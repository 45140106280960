import React from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';
import createNetworkingClient from 'utils/networking-client';
import StravaMentionsInput from '../StravaMentionsInput';

import styles from './styles.scss';

class MentionableComment extends React.Component {
  state = {
    isSubmitting: false,
    value: ''
  };

  submitComment = (e) => {
    e.preventDefault();

    const { entity, entityId } = this.props;
    const { value } = this.state;

    this.setState({ isSubmitting: true });
    createNetworkingClient()
      .post(`/feed/${entity.toLowerCase()}/${entityId}/comment`, {
        comment: value
      })
      .then((response) => {
        if (response && response.status === 200) {
          this.handleSubmitCommentComplete(response.data);
        } else {
          this.handleSubmitCommentFail();
        }
      })
      .catch(() => {
        this.handleSubmitCommentFail();
      });
  };

  handleSubmitCommentComplete = (data) => {
    const { entity, entityId, onSubmitSuccess } = this.props;

    this.setState({ value: '', isSubmitting: false });

    if (onSubmitSuccess) {
      onSubmitSuccess(data);
      return;
    }

    // Dispatch an event so that code outside or React can be executed
    // when a comment is created.
    const ev = new CustomEvent('commentCreatedFromReact', {
      detail: {
        response: data,
        entity,
        entityId
      }
    });
    document.dispatchEvent(ev);
  };

  handleSubmitCommentFail = () => {
    // TODO: handle error case
  };

  handleChange = ({ newValue }) => {
    // Avoids text duplication bug detailed here: https://github.com/signavio/react-mentions/issues/332
    const cleanValue = newValue.replace(/[\r]+/g, '');
    this.setState({ value: cleanValue });
  };

  render() {
    const { value, isSubmitting } = this.state;
    const { entity, entityId, clubMentionsEnabled } = this.props;
    const {
      placeHolder,
      str,
      textAreaClasses,
      buttonClasses,
      buttonText,
      autoFocus,
      viewingAthleteId
    } = this.props;

    const useButtonText =
      buttonText || I18n.t('templates.feed.comment_box.post');

    return (
      <>
        <StravaMentionsInput
          autoFocus={autoFocus}
          entity={entity}
          entityId={entityId}
          onInputChange={this.handleChange}
          clubMentionsEnabled={clubMentionsEnabled}
          surfaceType="comment"
          placeHolder={placeHolder}
          viewingAthleteId={viewingAthleteId}
          classNames={{
            mentions: 'media-body',
            mentions__highlighter: styles.highlighter,
            mentions__suggestions: styles.suggestions,
            mentions__suggestions__list: styles.suggestionList,
            mentions__input: textAreaClasses
          }}
        />
        <div className="media-actions">
          <button
            type="button"
            className={`text-footnote ${buttonClasses}`}
            disabled={isSubmitting || value.length === 0}
            onClick={this.submitComment}
            {...str}
          >
            {useButtonText}
          </button>
        </div>
      </>
    );
  }
}

MentionableComment.defaultProps = {
  str: null,
  placeHolder: null,
  textAreaClasses: null,
  buttonClasses: null,
  buttonText: null,
  suggestionLimit: null,
  autoFocus: false,
  onSubmitSuccess: null
};

MentionableComment.propTypes = {
  entity: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  clubMentionsEnabled: PropTypes.bool.isRequired,
  str: PropTypes.shape({
    'str-type': PropTypes.string.isRequired,
    'str-on': PropTypes.string.isRequired,
    'str-trackable-id': PropTypes.string.isRequired
  }),
  placeHolder: PropTypes.string,
  textAreaClasses: PropTypes.string,
  buttonClasses: PropTypes.string,
  buttonText: PropTypes.string,
  suggestionLimit: PropTypes.number,
  autoFocus: PropTypes.bool,
  onSubmitSuccess: PropTypes.func,
  viewingAthleteId: PropTypes.string.isRequired
};

export default MentionableComment;
