import React from 'react';
import Avatar from '@strava/ui/Avatar';
import PropTypes from 'prop-types';
import { Markup } from 'interweave';
import styles from './index.scss';
import { useKudosAndCommentsContext } from '../KudosAndCommentsContext';
import CommentReaction from '../CommentReaction';

const AvatarAndMeta = ({
  athleteMetaData,
  rowContent,
  showCommentReactions,
  entryId,
  commentReactionCount,
  hasReacted,
  entityId,
  commentReactionAnalytics
}) => {
  const { kudosAndCommentsEntityState } = useKudosAndCommentsContext();

  const activityName = athleteMetaData.activity_link ? (
    <>
      <div className={styles.spacer}>-</div>
      <div className={styles.activityLink}>
        <Markup noWrap={true} content={athleteMetaData.activity_link} />
      </div>
    </>
  ) : null;

  return (
    <div className={styles.avatarAndMeta}>
      <div className={styles.avatar}>
        <Avatar
          badge={athleteMetaData.member_type}
          href={athleteMetaData.url}
          name={athleteMetaData.name}
          size="small"
          src={athleteMetaData.avatar_url}
          type="athlete"
          onClick={() => {
            kudosAndCommentsEntityState.trackAnalytics({
              action: 'click',
              element: 'profile_picture',
              properties: { element_source: 'kudos_and_comments_modal_content' }
            });
          }}
        />
      </div>
      <div className={styles.meta}>
        <div className={styles.athleteName}>
          <a href={athleteMetaData.url}>{athleteMetaData.name}</a>
          {activityName}
        </div>
        {/* Location should not be shown if there is a content row (eg. comments) */}
        {!rowContent &&
          athleteMetaData.location &&
          athleteMetaData.location.length > 0 && (
            <div className={`text-caption1 ${styles.location}`}>
              {athleteMetaData.location}
            </div>
          )}
        {rowContent && (
          <div className={styles.rowContent}>
            {<Markup noWrap={true} content={rowContent} />}
            {showCommentReactions && (
              <CommentReaction
                hasReacted={hasReacted}
                commentReactionCount={commentReactionCount}
                commentId={entryId}
                entityId={entityId}
                analyticsProps={commentReactionAnalytics}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

AvatarAndMeta.propTypes = {
  athleteMetaData: PropTypes.shape({
    member_type: PropTypes.string,
    name: PropTypes.string.isRequired,
    avatar_url: PropTypes.string,
    location: PropTypes.string,
    url: PropTypes.string,
    activity_link: PropTypes.string
  }).isRequired,
  rowContent: PropTypes.string,
  showCommentReactions: PropTypes.bool.isRequired,
  entryId: PropTypes.number,
  commentReactionCount: PropTypes.number,
  hasReacted: PropTypes.bool,
  entityId: PropTypes.string.isRequired,
  commentReactionAnalytics: PropTypes.shape({
    category: PropTypes.string.isRequired,
    page: PropTypes.string.isRequired,
    buttonElement: PropTypes.string.isRequired,
    listElement: PropTypes.string.isRequired
  }).isRequired
};

AvatarAndMeta.defaultProps = {
  rowContent: null,
  entryId: null,
  commentReactionCount: 0,
  hasReacted: false
};

export default AvatarAndMeta;
