import React from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';
import Avatar from '@strava/ui/Avatar';

import createNetworkingClient from 'utils/networking-client';
import { trackV2 } from 'utils/analytics';
import I18n from 'utils/I18n';

import Comment from '../Comment';
import CreateComment from '../CreateComment';

import styles from './styles.scss';

export const I18nPrefix = 'components.comments';

class Comments extends React.Component {
  state = {
    expanded: false,
    comments: this.props.comments
  };

  deleteComment = (commentId, parentId) => {
    const instance = createNetworkingClient();
    const destUrl = `/posts/${this.props.parentId}/comments/${commentId}`;

    trackV2({
      category: 'challenges',
      page: 'comment',
      action: 'click',
      element: 'delete',
      properties: {
        comment_id: commentId,
        post_id: parentId
      }
    });

    // eslint-disable-next-line no-restricted-globals, no-alert
    if (window.confirm(I18n.t('strava.feed.comment_box_view.confirm_delete'))) {
      trackV2({
        category: 'challenges',
        page: 'comment',
        action: 'click',
        element: 'confirm_delete',
        properties: {
          comment_id: commentId,
          post_id: parentId
        }
      });

      instance
        .delete(destUrl)
        .then((response) => {
          if (response && response.status === 200) {
            this.onSuccess(response.data);
          } else {
            this.onFail();
          }
        })
        .catch(() => this.onFail());
    }
  };

  onFail = () => {
    throw new Error('Something went wrong deleting your comment');
  };

  onSuccess = (data) => {
    const { comments } = this.state;
    this.setState({
      comments: comments.filter((item) => item.id !== parseInt(data.id, 10))
    });
  };

  onCreateSuccess = (createdComment) => {
    this.setState((prevState) => ({
      comments: [...prevState.comments, createdComment]
    }));
  };

  toggleExpanded = () =>
    this.setState((prevState) => ({ expanded: !prevState.expanded }));

  showComments = () => {
    const { expanded, comments } = this.state;
    const { commentsToShow, parentId } = this.props;
    const startIndex = expanded ? 0 : comments.length - commentsToShow;

    return comments
      .slice(startIndex, comments.length)
      .map((comment) => (
        <Comment
          key={comment.id}
          id={comment.id}
          avatar={
            <Avatar
              href={`/athletes/${comment.author.id}`}
              name={comment.author.name}
              src={comment.author.avatar}
              size="xsmall"
              type="athlete"
            />
          }
          authorId={comment.author.id}
          authorName={comment.author.name}
          timestamp={comment.ts}
          deletable={comment.deletable}
          quarantinable={comment.quarantinable}
          reportable={comment.reportable}
          bodyText={comment.text}
          className={styles.comment}
          deleteComment={this.deleteComment}
          parentId={parentId}
        />
      ));
  };

  render() {
    const { comments, expanded } = this.state;
    const { className, commentsToShow, currentAthlete, parentId } = this.props;

    return (
      <div className={`${className} ${styles.container}`}>
        {comments.length > commentsToShow && (
          <div className={styles.buttonWrapper}>
            <Button
              type="button"
              variant="default"
              className={`btn btn-xs btn-block ${styles.showComments}`}
              onClick={this.toggleExpanded}
            >
              {I18n.t(`${I18nPrefix}.${expanded ? 'show_fewer' : 'show_more'}`)}
            </Button>
          </div>
        )}
        {this.showComments()}
        {currentAthlete && (
          <CreateComment
            parentId={parentId}
            onCreateSuccess={this.onCreateSuccess}
          />
        )}
      </div>
    );
  }
}

Comments.defaultProps = {
  comments: [],
  className: null,
  commentsToShow: 2,
  currentAthlete: null
};

Comments.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
        avatar: PropTypes.string
      }),
      ts: PropTypes.number,
      deletable: PropTypes.bool,
      quarantinable: PropTypes.bool,
      reportable: PropTypes.bool,
      text: PropTypes.string,
      id: PropTypes.number
    })
  ),
  parentId: PropTypes.number.isRequired,
  className: PropTypes.string,
  commentsToShow: PropTypes.number,
  currentAthlete: PropTypes.shape({})
};

export default Comments;
