import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const Handle = ({ style, domain: [min, max], handle, getHandleProps }) => {
  return (
    <button
      role="slider"
      type="button"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={handle.value}
      className={styles.slider}
      style={{
        left: `${handle.percent}%`,
        ...style
      }}
      {...getHandleProps(handle.id)}
    />
  );
};

Handle.defaultProps = {
  style: null
};

Handle.propTypes = {
  style: PropTypes.shape({}),
  domain: PropTypes.arrayOf(Number).isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired
};

export default Handle;
