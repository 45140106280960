import React from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';
import Spinner from '@strava/ui/Spinner';
import Avatar from '@strava/ui/Avatar';

import createNetworkingClient from 'utils/networking-client';
import I18n from 'utils/I18n';

import CreatePost from './components/CreatePost';
import Post from './components/Post';

import styles from './styles.scss';

const I18nPrefix = 'components.discussions';

class Discussion extends React.Component {
  state = {
    isLoading: true,
    posts: [],
    totalPosts: null,
    page: null,
    discussionPath: this.props.discussionPath,
    error: false,
    currentAthlete: this.props.currentAthlete
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = (params = {}) => {
    const { discussionPath } = this.state;
    const instance = createNetworkingClient();
    this.setState({ isLoading: true });

    instance
      .get(discussionPath, {
        params,
        headers: { Accept: 'text/javascript' }
      })
      .then((response) => {
        if (response && response.status === 200) {
          this.onFetchSuccess(response.data);
        } else {
          this.onFetchFail();
        }
      })
      .catch(() => this.onFetchFail());
  };

  onFetchFail = () => {
    this.setState({ isLoading: false, error: true });
  };

  onFetchSuccess = (data) => {
    this.setState((state) => ({
      isLoading: false,
      posts: [...state.posts, ...data.posts],
      totalPosts: data.total_posts,
      page: data.page
    }));
  };

  onCreateSuccess = (createdPost) => {
    this.setState((prevState) => ({
      posts: [createdPost, ...prevState.posts],
      totalPosts: prevState.totalPosts + 1
    }));
  };

  deletePost = (postId) => {
    const { discussionPath } = this.state;
    const instance = createNetworkingClient();

    instance
      .delete(`${discussionPath}/${postId}`)
      .then((response) => {
        if (response && response.status === 200) {
          this.onDeleteSuccess(response.data);
        } else {
          this.onDeleteFail();
        }
      })
      .catch(() => this.onDeleteFail());
  };

  onDeleteFail = () => {
    throw new Error('Something went wrong deleting your post');
  };

  onDeleteSuccess = (data) => {
    const { posts, totalPosts } = this.state;
    this.setState({
      posts: posts.filter((item) => item.id !== parseInt(data.id, 10)),
      totalPosts: totalPosts - 1
    });
  };

  reportPost = (postId) => {
    window.location = `/posts/${postId}/feedback`;
  };

  onSeeMore = () => {
    const { page } = this.state;
    this.fetchData({ page: page + 1 });
  };

  renderButtonOrSpinner = () => {
    const { isLoading, posts, totalPosts, error } = this.state;

    if (isLoading) {
      return (
        <div className={`mt-xl ${styles.buttonWrapper}`}>
          <Spinner />
        </div>
      );
    }
    if (posts.length < totalPosts && !isLoading) {
      return (
        <div className={`mt-xl ${styles.buttonWrapper}`}>
          <Button className="btn btn-default btn-sm" onClick={this.onSeeMore}>
            {I18n.t(`${I18nPrefix}.${error ? 'retry' : 'see_more'}`)}
          </Button>
        </div>
      );
    }
    return null;
  };

  renderPosts = () => {
    const { posts, currentAthlete } = this.state;

    return posts.map((post) => (
      <Post
        key={post.id}
        id={post.id}
        avatar={
          <Avatar
            href={`/athletes/${post.author.id}`}
            name={post.author.name}
            src={post.author.avatar}
            size="small"
            type="athlete"
          />
        }
        authorId={post.author.id}
        authorName={post.author.name}
        timestamp={post.ts}
        deletable={post.deletable}
        bodyText={post.text}
        className={styles.post}
        comments={post.comments}
        deletePost={this.deletePost}
        reportPost={this.reportPost}
        currentAthlete={currentAthlete}
      />
    ));
  };

  render() {
    const { posts, currentAthlete, discussionPath, isLoading } = this.state;

    return (
      <div className={styles.container}>
        {currentAthlete && (
          <CreatePost
            {...currentAthlete}
            discussionPath={discussionPath}
            onCreateSuccess={this.onCreateSuccess}
          />
        )}
        <div
          className={`${styles.discussionList} ${currentAthlete &&
            styles.indent}`}
        >
          {posts.length === 0 && !isLoading ? (
            <div className={`text-title3 ${styles.emptyState}`}>
              {I18n.t(`${I18nPrefix}.empty_state`)}
            </div>
          ) : (
            this.renderPosts()
          )}
        </div>
        {this.renderButtonOrSpinner()}
      </div>
    );
  }
}

Discussion.defaultProps = {
  currentAthlete: null
};

Discussion.propTypes = {
  currentAthlete: PropTypes.shape({}),
  discussionPath: PropTypes.string.isRequired
};

export default Discussion;
