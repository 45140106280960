import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';
import Avatar from '@strava/ui/Avatar';
import { trackV2 } from 'utils/analytics';

import I18n from 'utils/I18n';
import { useSafeState } from '@strava/react-hooks/src/use-safe-state';
import { setMentionInputStyles } from './MentionInputStyles';

import styles from './StravaMentionsInput.scss';
import { sortAndFilter } from './StravaMentionsInput.utils';
import { fetchMentionableEntities } from './StravaMentionsInput.cache';

const StravaMentionsInput = ({
  viewingAthleteId,
  entity,
  entityId,
  placeHolder,
  suggestionLimit,
  autoFocus,
  clubMentionsEnabled,
  trackAnalytics,
  onInputChange,
  clearInputToggle,
  inputName,
  inputHeight,
  value,
  surfaceType,
  analyticsFields,
  classNames,
  onInputClickAnalytics
}) => {
  const [mentionableEntities, setMentionableEntities] = useSafeState([]);
  const [inputValue, setInputValue] = useState(value);
  const firstRender = useRef(true);

  const mentionInputStyles = setMentionInputStyles({ inputHeight });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setInputValue('');
  }, [clearInputToggle]);

  useEffect(() => {
    fetchMentionableEntities({ viewingAthleteId }).then((mentionables) => {
      setMentionableEntities(mentionables);
    });
  }, [entity, entityId, setMentionableEntities, viewingAthleteId]);

  const nameFromId = (id) => {
    if (mentionableEntities && mentionableEntities.length > 0) {
      const filteredEntity = mentionableEntities.find((a) => a.id === id);

      if (filteredEntity) {
        return filteredEntity.display;
      }
    }
    return '';
  };

  const filteredMentionableEntities = (query) => {
    if (analyticsFields && query.length === 0) {
      trackV2({
        category: analyticsFields.category,
        page: analyticsFields.page,
        action: 'screen_enter',
        element: 'mentions_list'
      });
    }

    const results = mentionableEntities.filter(
      (a) => a.display.toLowerCase().indexOf(query.toLowerCase()) >= 0
    );
    return results.slice(0, suggestionLimit);
  };

  const fetchEntities = (e) => {
    if (e.key === '@') {
      fetchMentionableEntities({
        entity,
        entityId,
        viewingAthleteId,
        surfaceType
      }).then((mentionables) => {
        const filtered = sortAndFilter(
          mentionableEntities.concat(mentionables),
          viewingAthleteId
        );

        setMentionableEntities(filtered);
      });
    }
  };
  const onAddMentionableEntity = (id) => {
    if (analyticsFields) {
      const numericId = id.split('/')[1];
      const entityType = clubMentionsEnabled
        ? mentionableEntities.find((a) => a.id === id).type
        : 'athlete';

      trackV2({
        category: analyticsFields.category,
        page: analyticsFields.page,
        action: 'click',
        element: 'mentions_list',
        properties: {
          mentioned_athlete_id:
            clubMentionsEnabled && entityType === 'athlete' ? numericId : null,
          mentioned_club_id:
            clubMentionsEnabled && entityType === 'club' ? numericId : null
        }
      });
    }
  };

  const renderSuggestion = (entry) => {
    const avatarStyle = { marginRight: '8px' };

    return (
      <div className={styles.suggestion} data-testid="suggestions">
        <Avatar
          name={entry.display}
          src={
            clubMentionsEnabled && entry.type && entry.type === 'club'
              ? entry.image
              : entry.profile
          }
          size="small"
          type={clubMentionsEnabled && entry.type ? entry.type : 'athlete'}
          style={avatarStyle}
          badge={entry.member_type}
          onClick={() => {
            trackAnalytics({
              action: 'click',
              element: 'profile_picture',
              properties: { element_source: 'mentionable-athlete-suggestions' }
            });
          }}
        />
        <div className={styles.metaContainer}>
          <div className={styles.entityName}>{entry.display}</div>
          {entry.location && entry.location.length > 0 && (
            <div>
              {clubMentionsEnabled && entry.type === 'club' && (
                <>
                  <span className={`text-caption2 ${styles.location}`}>
                    {I18n.t('components.mentions.club')}
                  </span>
                  <span className={styles.locationDivider}>・</span>
                </>
              )}
              <span className={`text-caption2 ${styles.location}`}>
                {entry.location}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <MentionsInput
        allowSpaceInQuery={true}
        autoFocus={autoFocus}
        style={mentionInputStyles}
        onClick={onInputClickAnalytics}
        classNames={{
          mentions: styles.body,
          mentions__highlighter: styles.highlighter,
          mentions__suggestions: styles.suggestions,
          mentions__suggestions__list: styles.suggestionList,
          ...classNames
        }}
        value={inputValue}
        placeholder={placeHolder}
        onChange={(event, newValue, newPlainTextValue, mentions) => {
          setInputValue(newValue);
          onInputChange({ event, newValue, newPlainTextValue, mentions });
        }}
        onKeyDown={fetchEntities}
        a11ySuggestionsListLabel={I18n.t('components.mentions.list_label')}
      >
        <Mention
          appendSpaceOnAdd={true}
          data={filteredMentionableEntities}
          displayTransform={nameFromId}
          // Possible TODO: make patch into React-Mentions library to access a function
          // or a string for what we pass to the markup prop
          markup="[strava://__id__]"
          renderSuggestion={renderSuggestion}
          className={styles.mentionedEntity}
          onAdd={onAddMentionableEntity}
        />
      </MentionsInput>
      {inputName && (
        <input name={inputName} type="hidden" value={value || ''} />
      )}
    </>
  );
};

StravaMentionsInput.defaultProps = {
  placeHolder: null,
  suggestionLimit: 5,
  autoFocus: false,
  trackAnalytics: () => {},
  onInputChange: () => {},
  clearInputToggle: false,
  inputHeight: 60,
  value: '',
  surfaceType: 'comment',
  analyticsFields: {
    page: '',
    category: ''
  },
  entityId: null,
  classNames: null,
  onInputClickAnalytics: () => {},
  inputName: ''
};

StravaMentionsInput.propTypes = {
  entity: PropTypes.oneOf(['activity', 'group_activity', 'post']).isRequired,
  entityId: PropTypes.string,
  placeHolder: PropTypes.string,
  suggestionLimit: PropTypes.number,
  autoFocus: PropTypes.bool,
  viewingAthleteId: PropTypes.string.isRequired,
  trackAnalytics: PropTypes.func,
  inputName: PropTypes.string,
  onInputChange: PropTypes.func,
  clubMentionsEnabled: PropTypes.bool.isRequired,
  clearInputToggle: PropTypes.bool,
  inputHeight: PropTypes.number,
  value: PropTypes.string,
  surfaceType: PropTypes.string,
  analyticsFields: PropTypes.shape({
    page: PropTypes.string,
    category: PropTypes.string
  }),
  classNames: PropTypes.shape({}),
  onInputClickAnalytics: PropTypes.func
};

export default StravaMentionsInput;
