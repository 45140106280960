import React from 'react';
import PropTypes from 'prop-types';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import { trackV2 } from 'utils/analytics';
import createNetworkingClient from 'utils/networking-client';

import Breadcrumbs from './components/Breadcrumbs';
import SurveyLanding from './components/SurveyLanding';
import SurveyStep from './components/SurveyStep';

class FeedbackApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false
    };
  }

  trackEvent = ({ action, element, properties }) => {
    const { analytics } = this.props;

    trackV2({
      category: 'feedback',
      page: analytics.page,
      action,
      element,
      properties: {
        ...analytics.properties,
        ...properties
      }
    });
  };

  handleCancel = ({ surveyKey, selections, freeformText }) => {
    const { origin_path: originPath } = this.props;

    this.trackEvent({
      action: 'click',
      element: 'cancel',
      properties: {
        ...selections,
        ...(surveyKey && { survey_key: surveyKey }),
        ...(freeformText && { response_text: freeformText })
      }
    });

    window.location.href = originPath;
  };

  handleNext = ({ selections }) => {
    this.trackEvent({
      action: 'click',
      element: 'next',
      properties: {
        ...selections
      }
    });
  };

  handleSubmit = ({ surveyKey, selections, freeformText }) => {
    const { isSubmitting } = this.state;

    if (isSubmitting) return;
    this.setState({ isSubmitting: true });

    const {
      origin_path: originPath,
      submit_path: submitPath,
      surveys
    } = this.props;
    const { survey: selectedSurvey } = surveys.find(
      ({ survey }) => survey.survey_key === surveyKey
    );
    const properties = {
      ...selections,
      survey_key: surveyKey,
      ...(freeformText && { response_text: freeformText })
    };

    this.trackEvent({ action: 'click', element: 'submit', properties });

    const submitData = {
      survey_key: surveyKey,
      selections
    };

    createNetworkingClient()
      .post(submitPath, submitData)
      .then((response) => {
        if (response && response.status === 201) {
          // eslint-disable-next-line no-alert
          alert(
            `${selectedSurvey.footnote_title}\n\n${selectedSurvey.footnote_description}`
          );

          this.trackEvent({ action: 'click', element: 'ok', properties });

          window.location.href = originPath;

          return;
        }

        this.setState({ isSubmitting: false });
      })
      .catch(() => {
        this.setState({ isSubmitting: false });
        window.location.reload();
      });
  };

  render() {
    const {
      origin_path: originPath,
      origin_name: originalName,
      title,
      subtitle,
      eu_web_link: euWebLink,
      screen_name: screenName,
      surveys
    } = this.props;
    const { isSubmitting } = this.state;

    const breadcrumbs = [
      {
        href: originPath,
        text: originalName
      },
      {
        href: '/',
        text: screenName
      }
    ];

    return (
      <Router>
        <Switch>
          {surveys
            .filter(({ survey }) => !survey.external_path)
            .map(({ survey }) => (
              <Route path={`/${survey.survey_key}`} key={survey.survey_key}>
                <Breadcrumbs
                  breadcrumbs={[
                    ...breadcrumbs,
                    {
                      href: `/${survey.survey_key}`,
                      text: survey.screen_name
                    }
                  ]}
                />
                <SurveyStep
                  {...survey}
                  isSubmitting={isSubmitting}
                  handleCancel={this.handleCancel}
                  handleNext={this.handleSubmit}
                />
              </Route>
            ))}

          {/* this needs to be last so if users enter some rubbish path we show first step */}
          <Route path="/">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <SurveyLanding
              screen_name={screenName}
              title={title}
              subtitle={subtitle}
              euWebLink={euWebLink}
              questions={surveys.map(({ label, survey }) => ({
                type: survey.survey_key,
                text: label,
                externalPath: survey.external_path
              }))}
              handleCancel={this.handleCancel}
              handleNext={this.handleNext}
            />
          </Route>
        </Switch>
      </Router>
    );
  }
}

FeedbackApp.defaultProps = {
  subtitle: null,
  eu_web_link: null
};

FeedbackApp.propTypes = {
  origin_path: PropTypes.string.isRequired,
  origin_name: PropTypes.string.isRequired,
  submit_path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  eu_web_link: PropTypes.string,
  screen_name: PropTypes.string.isRequired,
  surveys: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      survey: PropTypes.shape({}).isRequired
    })
  ).isRequired,
  analytics: PropTypes.shape({
    page: PropTypes.string.isRequired,
    properties: PropTypes.shape({})
  }).isRequired
};

export default FeedbackApp;
