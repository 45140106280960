import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery, { breakpoints } from 'utils/media-query';

import styles from './styles.scss';

const Header = ({
  className,
  coverImageUrl,
  name,
  subtitle,
  challengeLogoUrl,
  coverTitleText,
  logoTitleText
}) => (
  <div className={className}>
    <div
      className={styles.hero}
      style={{ backgroundImage: `url(${coverImageUrl})` }}
      role="img"
      aria-label={coverTitleText}
      title={coverTitleText}
    />
    <div className={styles.heroGroup}>
      <img
        className={styles.challengeLogo}
        src={challengeLogoUrl}
        alt={logoTitleText}
        title={logoTitleText}
      />

      {/* Bigger screens - tablets & desktop */}
      <MediaQuery minWidth={breakpoints.screenXs}>
        <h1 className={`text-display3 ${styles.title}`}>{name}</h1>
      </MediaQuery>

      {/* Smaller screens - mobile */}
      <MediaQuery maxWidth={breakpoints.screenXs}>
        <h1 className={`text-title2 ${styles.title}`}>{name}</h1>
      </MediaQuery>
    </div>

    {/* Bigger screens - tablets & desktop */}
    <MediaQuery minWidth={breakpoints.screenXs}>
      <h3 className={`text-title3 ${styles.subtitle}`}>{subtitle}</h3>
    </MediaQuery>

    {/* Smaller screens - mobile */}
    <MediaQuery maxWidth={breakpoints.screenXs}>
      <h3 className={`text-subhead ${styles.subtitle}`}>{subtitle}</h3>
    </MediaQuery>
  </div>
);

Header.defaultProps = {
  className: ''
};

Header.propTypes = {
  className: PropTypes.string,
  coverImageUrl: PropTypes.string.isRequired,
  challengeLogoUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  coverTitleText: PropTypes.string.isRequired,
  logoTitleText: PropTypes.string.isRequired
};

export default Header;
