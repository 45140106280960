import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Markup } from 'interweave';

import styles from './styles.scss';

const types = {
  SINGLE_SELECT: 'single_select',
  MULTI_SELECT: 'multi_select'
};

const OptionList = ({ type, options, disabled, onChange }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (e) => {
    const { value, checked } = e.target;

    if (types.SINGLE_SELECT === type) {
      setSelectedValues([value]);
      return;
    }

    if (checked) {
      setSelectedValues((prevValues) => [...prevValues, value]);
    } else {
      setSelectedValues((prevValues) => prevValues.filter((v) => v !== value));
    }
  };

  useEffect(() => {
    onChange(selectedValues);
  }, [selectedValues]);

  return (
    <ul className={styles.list}>
      {options.map(({ value, text }) => {
        const isSelected = selectedValues.indexOf(value) !== -1;
        const id = `option-${value}`;

        return (
          <li key={value} className={styles.item}>
            <label htmlFor={id} className="text-book">
              {types.SINGLE_SELECT === type ? (
                <input
                  type="radio"
                  id={id}
                  value={value}
                  checked={isSelected}
                  disabled={disabled}
                  onChange={handleChange}
                  tabIndex={isSelected ? '0' : '-1'}
                />
              ) : (
                <input
                  type="checkbox"
                  id={id}
                  value={value}
                  checked={isSelected}
                  disabled={disabled}
                  onChange={handleChange}
                  tabIndex={isSelected ? '0' : '-1'}
                />
              )}

              <Markup noWrap={true} content={text} />
            </label>
          </li>
        );
      })}
    </ul>
  );
};

OptionList.defaultProps = {
  disabled: false
};

OptionList.propTypes = {
  type: PropTypes.oneOf(Object.keys(types).map((k) => types[k])).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default OptionList;
