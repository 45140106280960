import React, { useState } from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';
import Spinner from '@strava/ui/Spinner';
import StepActions from '../../components/StepActions';

import styles from './styles.scss';

const I18nPrefix = 'publishes.wizard';

function Step2({
  isLoading,
  isEdit,
  selectedId,
  segments,
  onSegmentClick,
  onNextBtnClick,
  onCancelBtnClick
}) {
  const [clickedId, setClickedId] = useState(null);

  return (
    <>
      <h2>{I18n.t(`${I18nPrefix}.verify_similar`)}</h2>
      <p>{I18n.t(`${I18nPrefix}.verify_similar_more`)}</p>
      <ul className={styles.list}>
        <li>
          <label>
            <input
              type="radio"
              name="segment"
              checked={selectedId === null}
              onChange={() => onSegmentClick(null)}
            />
            {I18n.t(`${I18nPrefix}.segment_${isEdit ? 'edit' : 'create'}`)}
          </label>
        </li>
        {segments.map((segment) => (
          <li key={segment.id}>
            <label>
              <input
                type="radio"
                name="segment"
                checked={selectedId === segment.id}
                onChange={() => {
                  setClickedId(segment.id);
                  onSegmentClick(segment.id);
                }}
              />
              {I18n.t(`${I18nPrefix}.${isEdit ? 'use' : 'view'}_segment`, {
                segment: segment.name
              })}
              {isLoading && clickedId === segment.id && (
                <span className={styles.spinner}>
                  <Spinner size={14} />
                </span>
              )}
            </label>
          </li>
        ))}
      </ul>
      <StepActions
        disabled={isLoading}
        actionVariant="next"
        onNext={onNextBtnClick}
        onCancel={onCancelBtnClick}
      />
    </>
  );
}

Step2.defaultProps = {
  isLoading: false,
  isEdit: false,
  selectedId: null
};

Step2.propTypes = {
  isLoading: PropTypes.bool,
  isEdit: PropTypes.bool,
  selectedId: PropTypes.number,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  onSegmentClick: PropTypes.func.isRequired,
  onNextBtnClick: PropTypes.func.isRequired,
  onCancelBtnClick: PropTypes.func.isRequired
};

export default Step2;
