import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';

import Analytics from 'utils/analytics';

import reducer from './reducers';

import Achievements from './components/Achievements';
import Club from './components/Club';
import Header from './components/Header';
import Summary from '../shared/components/Summary';
import CollapsibleSection from '../shared/components/CollapsibleSection';
import Followers from './components/Followers';
import Progress from './components/Progress';
import TopSidebar from './components/TopSidebar';

import styles from './styles.scss';
import ProgressBar from '../shared/components/ProgressBar';

class Show extends React.Component {
  store = createStore(reducer, { ...this.props }, applyMiddleware(thunk));

  componentDidMount() {
    const { challengeId } = this.props;

    Analytics.trackV2({
      action: 'screen_enter',
      category: 'challenges',
      page: 'challenge_details',
      properties: { challenge_id: challengeId }
    });
  }

  render() {
    const {
      challengeId,
      activityType,
      club,
      header,
      sections,
      followersInChallenge,
      summary,
      achievements,
      currentAthlete,
      joined,
      locale,
      teamProgressBar
    } = this.props;

    return (
      <Provider store={this.store}>
        <main role="main">
          <Header className={`${styles.header}`} {...header} />
          <div className={`container ${styles.topRow}`}>
            <div className="col-md-push-8 col-md-4 col-sm-12 mb-xl">
              <TopSidebar
                locale={locale}
                showTeamStats={teamProgressBar === null}
              />
            </div>
            <div className="col-md-pull-4 col-md-7 col-sm-12">
              <Progress />
              <Summary summary={summary} />
              {followersInChallenge && (
                <Followers
                  {...followersInChallenge}
                  challengeId={challengeId}
                  challengeLogo={header.challengeLogoUrl}
                  challengeName={header.name}
                />
              )}
            </div>
          </div>
          <div className={`container ${styles.teamChallengeRow}`}>
            <div className="col-md-push-8 col-md-4 col-sm-12 mb-xl">
              {teamProgressBar && (
                <TopSidebar
                  locale={locale}
                  onlyTeamStats={true}
                  showTeamStats={true}
                />
              )}
            </div>
            <div className="col-md-pull-4 col-md-7 col-sm-12">
              <div className="mb-xl">
                {teamProgressBar && <ProgressBar {...teamProgressBar.data} />}
              </div>
            </div>
          </div>
          <div className={`container ${styles.bottomRow}`}>
            <div className="col-md-7 col-sm-12">
              <CollapsibleSection
                challengeId={challengeId}
                activityType={activityType}
                sections={sections}
                currentAthlete={currentAthlete}
                joined={joined}
              />
            </div>
            <div className="col-md-offset-1 col-md-4 col-sm-12">
              {achievements && (
                <Achievements
                  className={club && styles.achievements}
                  {...achievements}
                />
              )}
              {club && <Club className="club" />}
            </div>
          </div>
        </main>
      </Provider>
    );
  }
}

Show.defaultProps = {
  club: null,
  followersInChallenge: null,
  achievements: null,
  gatingConditions: null,
  currentAthlete: null,
  gatingModal: null, // optional object that describes the (age) gating modal
  teamProgressBar: null // optional object present if challenge has both individual and team goals
};

Show.propTypes = {
  challengeId: PropTypes.number.isRequired,
  activityType: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  joined: PropTypes.bool.isRequired,
  ended: PropTypes.bool.isRequired,
  header: PropTypes.shape({
    challengeLogoUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    coverImageUrl: PropTypes.string.isRequired,
    logoTitleText: PropTypes.string.isRequired,
    coverTitleText: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired
  }).isRequired,
  club: PropTypes.shape({}),
  sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  followersInChallenge: PropTypes.shape({}),
  summary: PropTypes.shape({}).isRequired,
  achievements: PropTypes.shape({}),
  gatingConditions: PropTypes.shape({}),
  currentAthlete: PropTypes.shape({}),
  gatingModal: PropTypes.shape({}),
  locale: PropTypes.string.isRequired,
  teamProgressBar: PropTypes.shape({
    streaksCalendar: PropTypes.bool.isRequired,
    data: PropTypes.shape({}).isRequired
  })
};

export default Show;
