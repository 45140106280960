import React, { useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';

import createNetworkingClient from 'utils/networking-client';

import {
  FetchInProgress,
  FetchError,
  NoAccess
} from './components/LoadingStates';

import Footer from './components/Footer';
import ProgressGoalPeriodList from './components/ProgressGoalPeriodList';

const displayStates = {
  NO_ACCESS: 0,
  LOADING: 1,
  FETCH_SUCCESS: 2,
  FETCH_ERROR: 3
};

const periodRenderOrder = ['week', 'month', 'year'];

function ProgressGoalsSidebar({ unitPreference, hasAccess, athleteId }) {
  const showFooter = true;

  const formatters = useMemo(() => {
    const unitSystem = unitPreference === 'feet' ? 'imperial' : 'metric';

    return {
      elevationFormatter: I18n.elevationFormatter(unitSystem),
      distanceFormatter: I18n.distanceFormatter(unitSystem),
      timespanFormatter: I18n.timespanFormatter()
    };
  }, [unitPreference]);
  const [displayState, setDisplayState] = useState(
    hasAccess === true ? displayStates.LOADING : displayStates.NO_ACCESS
  );
  const [goalsByPeriod, setGoalsByPeriod] = useState({});

  const handleFetchComplete = useCallback((data) => {
    // Group activities by their period
    const grouped = data.reduce((acc, gl) => {
      (acc[gl.period] = acc[gl.period] || []).push(gl);
      return acc;
    }, []);
    setGoalsByPeriod(grouped);
    setDisplayState(displayStates.FETCH_SUCCESS);
  }, []);

  const handleFetchError = useCallback(() => {
    setDisplayState(displayStates.FETCH_ERROR);
  }, []);

  const fetchData = useCallback(() => {
    createNetworkingClient()
      .get(`/athletes/${athleteId}/goals/goals_sidebar_v2`)
      .then((response) => {
        if (response && response.status === 200) {
          handleFetchComplete(response.data);
        } else {
          handleFetchError();
        }
      })
      .catch(() => {
        handleFetchError();
      });
  }, []);

  useEffect(() => {
    if (hasAccess === true) {
      fetchData();
    }
  }, []);

  const goalDisplay = () => {
    switch (displayState) {
      case displayStates.NO_ACCESS:
        return <NoAccess />;
      case displayStates.LOADING:
        return <FetchInProgress />;
      case displayStates.FETCH_ERROR:
        return <FetchError handleRetry={fetchData} />;
      default:
        return periodRenderOrder
          .filter((period) => period in goalsByPeriod)
          .map((period) => (
            <ProgressGoalPeriodList
              key={period}
              periodName={period}
              goals={goalsByPeriod[period]}
              formatters={formatters}
            />
          ));
    }
  };

  return (
    <>
      <div className="goal-periods">{goalDisplay()}</div>
      {showFooter && <Footer />}
    </>
  );
}

ProgressGoalsSidebar.defaultProps = {};

ProgressGoalsSidebar.propTypes = {
  athleteId: PropTypes.number.isRequired,
  unitPreference: PropTypes.string.isRequired,
  hasAccess: PropTypes.bool.isRequired
};

export default ProgressGoalsSidebar;
