import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import cx from '@strava/ui/clsx';
import ActionsVideoThumbnailNormalXsmall from '@strava/icons/ActionsVideoThumbnailNormalXsmall';

import StravaMediaLightbox from 'components/StravaMediaLightbox';
import { trackV2 } from 'utils/analytics';

import styles from './MediaThumbnailList.scss';

function MediaThumbnailList({
  isLoggedIn,
  items,
  viewableCount,
  thumbnailSize,
  category,
  profileType,
  page
}) {
  const [lightboxState, setLightboxState] = useState({
    isOpen: false,
    selectedIndex: 0
  });

  const itemsToDisplay = viewableCount - 1;
  const hasMoreItems = items.length - viewableCount > 0;
  const moreCount = items.length - itemsToDisplay;
  const adjustedItems = hasMoreItems ? items.slice(0, itemsToDisplay) : items;

  useEffect(() => {
    if (window.location.pathname.includes('/activities')) {
      // Provided that URL includes a unique videoId param then we can assert that URL is a redirect from BranchIO deep link.
      // Then we can programmatically trigger the Modal (StravaMediaLightbox) and select that video to display.
      // Deep link format => `/activities/<activityId>?videoId=<video_id>`
      const urlParams = new URLSearchParams(window.location.search);
      // Grab unique videoId param from deep link
      const videoId = urlParams.get('videoId');
      // If no videoId param then it's not a deep link so do nothing.
      if (videoId) {
        const photoIndex = items.findIndex((item) => item.photo_id === videoId);
        if (photoIndex >= 0) {
          setLightboxState({ isOpen: true, selectedIndex: photoIndex });
        }
      }
    }
  }, [items]);

  // Analytics for screen enter/exit
  useEffect(() => {
    if (items.length === 0) {
      return;
    }
    const firstItem = items[0];
    const isAdp = page === 'activity_detail';
    const data = {
      category,
      page,
      element: isAdp ? 'media' : 'media_thumbnail',
      properties: {
        athlete_id: firstItem.viewing_athlete_id,
        requested_athlete_id: firstItem.owner_id
      }
    };

    if (isAdp) {
      data.properties.activity_id = firstItem.activity_id;
      data.properties.activity_athlete_id = firstItem.owner_id;
    }

    trackV2({
      ...data,
      action: 'screen_enter'
    });

    window.addEventListener('beforeunload', function() {
      trackV2({
        ...data,
        action: 'screen_exit'
      });
    });
  }, []);

  function handleItemClick(index) {
    setLightboxState({ isOpen: true, selectedIndex: index });
  }

  const onMediaClick = (media) => {
    const data = {
      category,
      page,
      properties: {
        activity_id: media.activity_id,
        activity_athlete_id: media.owner_id,
        athlete_id: media.viewing_athlete_id,
        requested_athlete_id: media.owner_id
      }
    };

    const isImage = media.media_type === 1;
    if (isImage) {
      data.properties.image_id = media.photo_id;
    } else {
      data.properties.video_id = media.photo_id;
    }

    // Photo / Video
    const adpElement = isImage ? 'image' : 'video';
    const profileElement = isImage ? 'photo_thumbnail' : 'video_thumbnail';

    trackV2({
      ...data,
      action: 'click',
      element: category === 'activity_detail' ? adpElement : profileElement
    });
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <MediaList>
        {adjustedItems.map((item, index) => (
          <MediaListItem
            key={item.photo_id}
            style={{ height: thumbnailSize, width: thumbnailSize }}
          >
            <Item
              isInteractive={isLoggedIn}
              onClick={() => handleItemClick(index)}
            >
              <img src={item.thumbnail} alt="" />
              {item.media_type === 2 ? <VideoIndicator /> : null}
            </Item>
          </MediaListItem>
        ))}

        {hasMoreItems ? (
          <MediaListItem
            style={{ height: thumbnailSize, width: thumbnailSize }}
          >
            <MoreItem
              isInteractive={isLoggedIn}
              onClick={() => handleItemClick(itemsToDisplay)}
            >
              <img src={items[itemsToDisplay].thumbnail} alt="" />
              {items[itemsToDisplay].media_type === 2 ? (
                <VideoIndicator />
              ) : null}
              <div className={styles.count}>+ {moreCount}</div>
            </MoreItem>
          </MediaListItem>
        ) : null}
      </MediaList>

      {lightboxState.isOpen ? (
        <StravaMediaLightbox
          selectedPhotoIndex={lightboxState.selectedIndex}
          photoList={items}
          onMount={() => {
            onMediaClick(items[lightboxState.selectedIndex]);
          }}
          onCloseRequest={() => {
            setLightboxState({ isOpen: false });
          }}
          analyticProps={{
            page,
            feedType: profileType,
            entityId: items[lightboxState.selectedIndex].activity_id
          }}
        />
      ) : null}
    </>
  );
}

MediaThumbnailList.defaultProps = {
  isLoggedIn: false,
  items: [],
  viewableCount: 8,
  thumbnailSize: 60
};

MediaThumbnailList.propTypes = {
  isLoggedIn: PropTypes.bool,
  items: PropTypes.arrayOf(
    // TODO: Look into if we have have this shared somehow
    PropTypes.shape({
      media_type: PropTypes.number.isRequired,
      photo_id: PropTypes.string.isRequired,
      large: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
      caption_escaped: PropTypes.string,
      viewing_athlete_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      owner_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      editable: PropTypes.bool,
      activity_id: PropTypes.string
    })
  ),
  viewableCount: PropTypes.number,
  thumbnailSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  category: PropTypes.string.isRequired,
  profileType: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired
};

function Item({ isInteractive, children, onClick }) {
  return isInteractive ? (
    <MediaListItemButton onClick={onClick} onKeyPress={onClick}>
      {children}
    </MediaListItemButton>
  ) : (
    <div className={styles.buttonLoggedOut}>{children}</div>
  );
}

Item.propTypes = {
  isInteractive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

function MoreItem({ isInteractive, children, onClick }) {
  return isInteractive ? (
    <MediaListItemButton
      className={styles.seeMoreButton}
      onClick={onClick}
      onKeyPress={onClick}
    >
      {children}
    </MediaListItemButton>
  ) : (
    <div className={cx(styles.buttonLoggedOut, styles.seeMoreButtonLoggedOut)}>
      {children}
    </div>
  );
}

MoreItem.propTypes = {
  isInteractive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

/**
 * MediaList
 */
function MediaList({ className, ...props }) {
  return <ul {...props} className={cx(styles.list, className)} />;
}

MediaList.defaultProps = {
  className: undefined
};

MediaList.propTypes = {
  className: PropTypes.string
};

/**
 * MediaListItem
 */

function MediaListItem({ className, ...props }) {
  return <li {...props} className={cx(styles.item, className)} />;
}

MediaListItem.defaultProps = {
  className: undefined
};

MediaListItem.propTypes = {
  className: PropTypes.string
};

/**
 * MediaListItemButton
 */

function MediaListItemButton({ className, ...props }) {
  return (
    <div
      {...props}
      role="button"
      className={cx(styles.button, className)}
      tabIndex="0"
    />
  );
}

MediaListItemButton.defaultProps = {
  className: undefined
};

MediaListItemButton.propTypes = {
  className: PropTypes.string
};

function VideoIndicator() {
  return (
    <div className={styles.videoIndicator} data-testid="video-indicator">
      <ActionsVideoThumbnailNormalXsmall />
    </div>
  );
}

export default MediaThumbnailList;
