import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '@strava/ui/Spinner';

import I18n from 'utils/I18n';
import styles from './styles.scss';
import FollowersListItem from '../FollowersListItem';
import { fetchFollowers, invite } from '../../actions';

export class FollowersList extends React.Component {
  state = {
    searchTerm: '',
    showEmptyState: false
  };

  componentDidMount() {
    this.props.handleFetchFollowers().then((response) => {
      if (
        response &&
        response.status === 200 &&
        response.data.results.followers.length === 0
      ) {
        this.setState({ showEmptyState: true });
      }
    });
  }

  renderLoadingState = () => (
    <div className={styles.loadingContainer}>
      <Spinner size={90} />
    </div>
  );

  renderErrorState = () => (
    // TODO: Get design feedback and translations here.
    <div className={styles.emptyStateContainer}>
      An error has occured fetching your followers, please try again later.
    </div>
  );

  renderEmptyState = () => (
    <div className={styles.emptyStateContainer}>
      <p>{I18n.t('strava.activities.tagging.invite_module.empty_state')}</p>
      <a
        href={this.props.findFriendsUrl}
        className={`${styles.findFriendsButton} btn`}
      >
        {I18n.t('strava.activities.tagging.invite_module.find_friend_cta')}
      </a>
    </div>
  );

  renderFollowersList = () => (
    <ul>
      {this.props.followers.map((follower) => (
        <FollowersListItem
          key={follower.athleteId}
          follower={follower}
          activityId={this.props.activityId}
          handleInvite={this.props.handleInvite}
        />
      ))}
    </ul>
  );

  filterList = (event) => {
    // Throttle calls while user is typing
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    const val = event.target.value.toLowerCase();
    this.setState({ searchTerm: val });

    this.timeout = setTimeout(() => {
      this.timeout = null;
      this.props.handleFetchFollowers(val);
    }, 200);
  };

  render() {
    const { loading, error } = this.props;
    let listContent;
    if (loading) {
      listContent = this.renderLoadingState();
    } else if (error !== '') {
      listContent = this.renderErrorState();
    } else if (this.state.showEmptyState) {
      listContent = this.renderEmptyState();
    } else {
      listContent = this.renderFollowersList();
    }
    return (
      <div className={styles.listContainer}>
        <div className={styles.searchBarContainer}>
          <input
            type="text"
            autoFocus={true}
            className={`${styles.searchBar} mb-md form-control form-control-lg`}
            value={this.state.searchTerm}
            placeholder={I18n.t(
              'strava.activities.tagging.invite_module.search_placeholder'
            )}
            onChange={this.filterList}
          />
        </div>
        <div className={styles.followersList}>{listContent}</div>
      </div>
    );
  }
}

FollowersList.propTypes = {
  followers: PropTypes.arrayOf(PropTypes.object).isRequired,
  activityId: PropTypes.number.isRequired,
  handleInvite: PropTypes.func.isRequired,
  handleFetchFollowers: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  findFriendsUrl: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    followers: state.followers,
    activityId: state.activityId,
    loading: state.loading,
    error: state.error,
    findFriendsUrl: state.findFriendsUrl
  };
}

export default connect(
  mapStateToProps,
  {
    handleInvite: invite,
    handleFetchFollowers: fetchFollowers
  }
)(FollowersList);
