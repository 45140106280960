import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { trackV2 } from 'utils/analytics';
import reducer from './reducers';

import ExpandableOptionList from './components/ExpandableOptionList';
import { logMessage } from '../../utils/sentry';

function getIndexOfOption(options, value) {
  return options.findIndex((o) => o.value === value);
}

function analyticsFactory({ category, page, properties = {} }) {
  return function analyticsReporter(location, action, element) {
    if (['inner', 'outer'].indexOf(location) === -1) {
      logMessage('Wrong location!');
      return;
    }
    trackV2({ category, page: page[location], action, element, properties });
  };
}

class VisibilitySetting extends React.Component {
  constructor(props) {
    super(props);

    const { options, selectedValue, analytics } = props;
    const optionIndex = getIndexOfOption(options, selectedValue);
    if (optionIndex === -1) {
      throw new Error('`selectedValue` not found in `options`');
    }

    this.store = createStore(
      reducer,
      {
        ...props,
        savedOptionIndex: optionIndex,
        selectedOptionIndex: optionIndex,
        analyticsReporter: analyticsFactory(analytics)
      },
      applyMiddleware(thunk)
    );
  }

  render() {
    return (
      <Provider store={this.store}>
        <ExpandableOptionList {...this.props} />
      </Provider>
    );
  }
}

VisibilitySetting.defaultProps = {
  athleteId: null,
  isSubmittable: false
};

VisibilitySetting.propTypes = {
  athleteId: PropTypes.number,
  isSubmittable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      detail: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,

  analytics: PropTypes.shape({
    category: PropTypes.string.isRequired,
    page: PropTypes.shape({
      outer: PropTypes.string,
      inner: PropTypes.string
    }).isRequired,
    properties: PropTypes.shape({
      activity_id: PropTypes.number
    })
  }).isRequired
};

export default VisibilitySetting;
