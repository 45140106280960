import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Avatar from '@strava/ui/Avatar';

import I18n from 'utils/I18n';

import styles from './styles.scss';

const I18nPrefix = 'strava.challenges.challenge_detail';

const FriendOption = ({ name, picture }) => (
  <div className={styles.container}>
    <Avatar
      name={name}
      type="athlete"
      size="xsmall"
      src={picture}
      className={styles.avatar}
    />
    <span className={styles.name}>{name}</span>
  </div>
);

FriendOption.propTypes = {
  name: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired
};

export const FriendSelect = ({ options, handleChange }) => {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      flex: 1,
      minHeight: 40
    }),
    control: (provided) => ({
      ...provided,
      border: 'solid 1px #ccccd0',
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.11)',
      borderRadius: 2,
      minHeight: 40
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      borderRadius: '0 0 2px 2px',
      border: 'solid 1px #ccccd0',
      borderTop: 'none',
      boxShadow: '0px 1px 3px 2px rgba(0,0,0,.1)'
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#F0F0F5'
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      color: 'inherit',
      ':hover': {
        backgroundColor: '#6D6D78',
        color: '#fff'
      }
    })
  };

  return (
    <Select
      options={options}
      delimiter=","
      isMulti={true}
      isSearchable={true}
      autoFocus={true}
      isClearable={false}
      onChange={handleChange}
      placeholder={I18n.t(`${I18nPrefix}.enter_friends`)}
      styles={customStyles}
      formatOptionLabel={FriendOption}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      components={{ DropdownIndicator: null }}
      noOptionsMessage={() => I18n.t(`${I18nPrefix}.no_options_available`)}
    />
  );
};

FriendSelect.defaultProps = {
  options: []
};

FriendSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      picture: PropTypes.string
    })
  ),
  handleChange: PropTypes.func.isRequired
};

export default FriendSelect;
