import React from 'react';
import PropTypes from 'prop-types';

import {
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalTitle,
  ModalBody
} from '@strava/ui/Modal';
import I18n from 'utils/I18n';
import { trackV2 } from 'utils/analytics';

import styles from './styles.scss';

import AbbrUnit from './components/AbbrUnit';

const ExcludedSegmentEffortsModal = ({
  entity,
  flaggedEfforts,
  speedUnit,
  isModalOpen,
  onDismiss
}) => {
  const trackModalClick = () => {
    trackV2({
      action: 'click',
      element: 'learn_more',
      category: 'segments',
      page: 'excluded_efforts',
      properties: { source: `${entity}_detail` }
    });
    return true;
  };

  const modalContent = () => {
    return (
      <>
        <ModalCloseButton className={styles.closeButton} onClick={onDismiss} />
        <ModalTitle id="excluded-segment-efforts-modal-title">
          {I18n.t(
            'strava.labs.activities.segments_view.excluded_efforts.heading'
          )}
        </ModalTitle>
        <ModalBody className={styles.excludedEfforts}>
          <p className={styles.eeDescription}>
            {I18n.t(
              'strava.labs.activities.segments_view.excluded_efforts.description1_v2'
            )}
          </p>
          <p className={styles.eeDescription}>
            {I18n.t_html(
              'strava.labs.activities.segments_view.excluded_efforts.description2_html',
              {
                learn_more_path:
                  'https://support.strava.com/hc/articles/360061896712'
              },
              {
                onLinkClick: trackModalClick
              }
            )}
          </p>
          <table>
            <thead>
              <tr>
                <th>{I18n.t('templates.activities.segments.table.name')}</th>
                <th>{I18n.t('templates.activities.segments.table.time')}</th>
                {speedUnit && (
                  <th>
                    {I18n.t(
                      `templates.activities.segments.table.speed.${speedUnit}`
                    )}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {flaggedEfforts.map((row) => (
                <tr key={row.id}>
                  <td className={styles.tableDataBody}>
                    <div>
                      {entity === 'segment' && (
                        <p className={styles.tableBody}>
                          <a href={row.path}>{row.date}</a>
                        </p>
                      )}
                      {entity === 'activity' && (
                        <p className={styles.tableBody}>
                          <a href={row.path}>{row.name}</a>
                        </p>
                      )}
                      <p className={styles.subtitle}>
                        {`${I18n.t(
                          'strava.labs.activities.segments_view.excluded_efforts.subtitle'
                        )} - `}{' '}
                        <AbbrUnit data={row.distance} />{' '}
                        <AbbrUnit data={row.elev_difference} />{' '}
                        <AbbrUnit data={row.avg_grade} />
                      </p>
                    </div>
                  </td>
                  <td className={styles.tableDataBody}>{row.elapsed_time}</td>
                  <td className={styles.tableDataBody}>
                    <AbbrUnit data={row.avg_speed} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalBody>
      </>
    );
  };

  return (
    <ModalOverlay
      isOpen={isModalOpen}
      onDismiss={onDismiss}
      className={styles.overlay}
    >
      <ModalContent aria-labelledby="excluded-segment-efforts-modal-title">
        {modalContent()}
      </ModalContent>
    </ModalOverlay>
  );
};

const abbrUnitType = PropTypes.shape({
  value: PropTypes.string,
  unit: PropTypes.shape({
    unit_long: PropTypes.string,
    unit_short: PropTypes.string
  })
}).isRequired;

ExcludedSegmentEffortsModal.defaultProps = {
  speedUnit: null
};

ExcludedSegmentEffortsModal.propTypes = {
  entity: PropTypes.oneOf(['activity', 'segment']).isRequired,
  flaggedEfforts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      path: PropTypes.string,
      date: PropTypes.string,
      name: PropTypes.string,
      distance: abbrUnitType,
      elev_difference: abbrUnitType,
      avg_grade: abbrUnitType,
      avg_speed: abbrUnitType,
      elapsed_time: PropTypes.string
    })
  ).isRequired,
  speedUnit: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired
};

export default ExcludedSegmentEffortsModal;
