import React from 'react';
import PropTypes from 'prop-types';

import { Tabs as ReactTabs } from 'react-tabs';

const Tabs = ({ children, className, defaultIndex, ...props }) => (
  <ReactTabs className={className} defaultIndex={defaultIndex} {...props}>
    {children}
  </ReactTabs>
);

Tabs.tabsRole = 'Tabs';

Tabs.defaultProps = {
  className: '',
  defaultIndex: 0
};

Tabs.propTypes = {
  className: PropTypes.string,
  defaultIndex: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

export default Tabs;
