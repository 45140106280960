import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Markup } from 'interweave';

import Radio from '@strava/ui/Radio';
import I18n from 'utils/I18n';

import BaseModal from '../BaseModal';

import styles from './styles.scss';

const ReportMediaModal = ({
  isOpen,
  isReported,
  isReporting,
  onDismiss,
  onSubmit
}) => {
  const [selectedOption, setSelectedOption] = useState('');

  const optionInappropriate = 'inappropriate';
  const optionHarassment = 'harassment';
  const optionNoConsent = 'no_consent';

  const labelStyles = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  };

  const handleOnDismiss = () => {
    setSelectedOption('');
    onDismiss();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(selectedOption);
    setSelectedOption('');
  };

  return (
    <BaseModal
      isOpen={isOpen}
      hasChanges={selectedOption !== ''}
      isSaving={isReporting}
      buttonLabels={{
        savingText: I18n.t('templates.feed.activity.report_photo.submitting'),
        saveText: I18n.t('templates.feed.activity.report_photo.submit')
      }}
      onDismiss={handleOnDismiss}
      onSubmit={handleSubmit}
      title={I18n.t('templates.feed.activity.report_photo.report_media')}
      hideSaveBtn={isReported}
    >
      {isReported && (
        <div className={styles.reportedPhotoMessage}>
          {I18n.t('templates.feed.activity.report_photo.report_success_media')}
        </div>
      )}

      {!isReported && (
        <>
          <span className={styles.euForm}>
            <Markup
              content={I18n.t(
                'templates.feed.activity.report_photo.eu_report.eu_web_link',
                {
                  link_url:
                    'https://support.strava.com/hc/requests/new?ticket_form_id=24760679798925'
                }
              )}
              noWrap={true}
            />
          </span>
          <label
            htmlFor={optionInappropriate}
            className={styles.label}
            style={labelStyles}
          >
            <Radio
              id={optionInappropriate}
              name="report-options"
              value={optionInappropriate}
              checked={selectedOption === optionInappropriate}
              onChange={() => setSelectedOption(optionInappropriate)}
            />
            <span className={styles.labelValue}>
              {I18n.t(
                'templates.feed.activity.report_photo.inappropriate_content.header'
              )}
            </span>
            <span className={styles.labelExample}>
              {I18n.t(
                'templates.feed.activity.report_photo.inappropriate_content.examples'
              )}
            </span>
          </label>

          <label
            htmlFor={optionHarassment}
            className={styles.label}
            style={labelStyles}
          >
            <Radio
              id={optionHarassment}
              name="report-options"
              value={optionHarassment}
              checked={selectedOption === optionHarassment}
              onChange={() => setSelectedOption(optionHarassment)}
            />
            <span className={styles.labelValue}>
              {I18n.t('templates.feed.activity.report_photo.harassment')}
            </span>
          </label>

          <label
            htmlFor={optionNoConsent}
            className={styles.label}
            style={labelStyles}
          >
            <Radio
              id={optionNoConsent}
              name="report-options"
              value={optionNoConsent}
              checked={selectedOption === optionNoConsent}
              onChange={() => setSelectedOption(optionNoConsent)}
            />
            <span className={styles.labelValue}>
              {I18n.t(
                'templates.feed.activity.report_photo.no_consent_from_me_media'
              )}
            </span>
          </label>
          <span className={styles.euForm}>
            <Markup
              content={I18n.t(
                'templates.feed.activity.report_photo.eu_report.additional_information',
                {
                  link_url:
                    'https://support.strava.com/hc/en-us/articles/216917777-How-Do-I-Contact-Strava-Support'
                }
              )}
              noWrap={true}
            />
          </span>
        </>
      )}
    </BaseModal>
  );
};

ReportMediaModal.propTypes = {
  isOpen: PropTypes.bool,
  isReported: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSubmit: PropTypes.func,
  isReporting: PropTypes.bool
};

ReportMediaModal.defaultProps = {
  isOpen: false,
  isReported: false,
  onDismiss: () => {},
  onSubmit: () => {},
  isReporting: false
};

export default ReportMediaModal;
