import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';

import { withMap, latLngToLngLat } from '@strava/ui/Map';
import PolygonCircle from '@strava/ui/Map/PolygonCircle';
import I18n from 'utils/I18n';

import styles from './PrivacyZones.scss';

let startPopup = null;
let endPopup = null;

function PrivacyZones({ map, zones, start, end }) {
  const zonesWithId = zones.map((zone, i) => ({
    ...zone,
    id: `privacy-zone-${i}`
  }));

  function createPopup(lngLat) {
    return new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: false,
      className: styles.popup,
      maxWidth: '320px'
    })
      .setLngLat(lngLat)
      .setHTML(I18n.t('strava.maps.privacy_zone_helper_v2'))
      .addTo(map);
  }

  function removeStartPopup() {
    startPopup.remove();
    startPopup = null;
  }

  function removeEndPopup() {
    endPopup.remove();
    endPopup = null;
  }

  function getFeaturesByLatLng(latLng) {
    return map.queryRenderedFeatures(map.project(latLngToLngLat(latLng)), {
      layers: zonesWithId.map((z) => z.id)
    });
  }

  function checkStartPopup() {
    if (start === null) return;
    const features = getFeaturesByLatLng(start);

    if (startPopup === null && features.length > 0) {
      features.forEach(({ properties }) => {
        startPopup = createPopup([properties.lng, properties.lat]);
      });
    }

    if (startPopup !== null && features.length === 0) {
      removeStartPopup();
    }
  }

  function checkEndPopup() {
    if (end === null) return;
    const features = getFeaturesByLatLng(end);

    if (endPopup === null && features.length > 0) {
      features.forEach(({ properties }) => {
        endPopup = createPopup([properties.lng, properties.lat]);
      });
    }

    if (endPopup !== null && features.length === 0) {
      removeEndPopup();
    }
  }

  // componentDidMount
  useEffect(() => {
    setTimeout(() => {
      checkStartPopup();
      checkEndPopup();
    }, 200);

    // componentWillUnmount
    return function cleanup() {
      if (startPopup) {
        removeStartPopup();
      }
      if (endPopup) {
        removeEndPopup();
      }
    };
  }, []);

  useEffect(() => {
    checkStartPopup();
  }, [start]);

  useEffect(() => {
    checkEndPopup();
  }, [end]);

  return (
    <>
      {zonesWithId.map((zone) => (
        <PolygonCircle
          key={zone.id}
          id={zone.id}
          lat={zone.position.lat}
          lng={zone.position.lng}
          // radius is in meeters and we need to conver it to kilometers
          radius={zone.radius / 1000}
          style={{
            fillColor: '#f00',
            fillOpacity: 0.35,
            strokeColor: '#f00',
            strokeOpacity: 0.5,
            strokeWeight: 1,
            strokeOffset: 0.5
          }}
        />
      ))}
    </>
  );
}

PrivacyZones.defaultProps = {
  start: null,
  end: null
};

PrivacyZones.propTypes = {
  map: PropTypes.shape().isRequired,
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired
      }).isRequired,
      radius: PropTypes.number.isRequired
    })
  ).isRequired,
  start: PropTypes.arrayOf(PropTypes.number),
  end: PropTypes.arrayOf(PropTypes.number)
};

export default withMap(PrivacyZones);
