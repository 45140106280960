import React from 'react';
import PropTypes from 'prop-types';

import Map from '@strava/ui/Map';
import Polyline from '@strava/ui/Map/Polyline';
import Marker from '@strava/ui/Map/Marker';

// Static Assets
import primaryStartMarker from '@strava/ui/Map/images/map-marker-start.png';
import primaryEndMarker from '@strava/ui/Map/images/map-marker-end.png';
import secondaryStartMarker from 'components/map/images/map-marker-range-start.png';
import secondaryEndMarker from 'components/map/images/map-marker-range-end.png';

import FitBounds from 'components/map/FitBounds';
import PrivacyZones from 'components/map/PrivacyZones';
import StyleNav from 'components/map/StyleNav';

// Stylesheets
import styles from './styles.scss';

function RangeSelectMap({
  currentStep,
  primaryLatLng,
  secondaryLatlng,
  fitBounds,
  privacyZones
}) {
  const showSecondaryLine = secondaryLatlng.length > 0;

  return (
    <Map
      latLng={primaryLatLng}
      className={styles.map}
      options={{
        minZoom: 9,
        maxZoom: 18
      }}
    >
      <StyleNav />

      {fitBounds.length > 0 && (
        <FitBounds
          latlng={fitBounds}
          start={fitBounds[0]}
          end={fitBounds.slice(-1)[0]}
          updateOnLatlngChange={currentStep === 2}
        />
      )}

      {privacyZones.length > 0 && (
        <PrivacyZones
          zones={privacyZones}
          start={secondaryLatlng[0]}
          end={secondaryLatlng.slice(-1)[0]}
        />
      )}

      <Polyline
        latLng={primaryLatLng}
        id="primary-polyline"
        style={{
          lineColor: '#105CB6',
          outlineWidth: 5
        }}
      />

      <Polyline
        latLng={secondaryLatlng}
        id="secondary-polyline"
        style={{
          lineColor: '#fc5200',
          outlineWidth: 0,
          opacity: currentStep === 2 ? 0.75 : 1
        }}
      />

      {/* Primary markers */}
      <Marker latLng={primaryLatLng[0]}>
        <img
          src={currentStep === 1 ? primaryStartMarker : secondaryStartMarker}
          alt="Start"
          className={
            currentStep === 1 ? styles.primaryMarker : styles.secondaryMarker
          }
        />
      </Marker>
      <Marker latLng={primaryLatLng.slice(-1)[0]}>
        <img
          src={currentStep === 1 ? primaryEndMarker : secondaryEndMarker}
          alt="End"
          className={
            currentStep === 1 ? styles.primaryMarker : styles.secondaryMarker
          }
        />
      </Marker>

      {/* Secondary markers */}
      {showSecondaryLine && (
        <Marker latLng={secondaryLatlng[0]}>
          <img
            src={currentStep === 1 ? secondaryStartMarker : primaryStartMarker}
            alt="Start"
            className={
              currentStep === 1 ? styles.secondaryMarker : styles.primaryMarker
            }
          />
        </Marker>
      )}
      {showSecondaryLine && (
        <Marker latLng={secondaryLatlng.slice(-1)[0]}>
          <img
            src={currentStep === 1 ? secondaryEndMarker : primaryEndMarker}
            alt="End"
            className={
              currentStep === 1 ? styles.secondaryMarker : styles.primaryMarker
            }
          />
        </Marker>
      )}
    </Map>
  );
}

RangeSelectMap.defaultProps = {
  currentStep: 1,
  secondaryLatlng: [],
  privacyZones: [],
  fitBounds: []
};

RangeSelectMap.propTypes = {
  currentStep: PropTypes.number,
  primaryLatLng: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    .isRequired,
  secondaryLatlng: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  privacyZones: PropTypes.arrayOf(PropTypes.shape()),
  fitBounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
};

export default RangeSelectMap;
