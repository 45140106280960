import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@strava/icons/NavigationInformationNormalXsmall';
import Button from '@strava/ui/Button';

import I18n from 'utils/I18n';
import { trackV2 } from 'utils/analytics';

import RPESlider from './components/RPESlider';

import styles from './styles.scss';
import Toggle from './components/Toggle';

export const I18nPrefix = 'strava.perceived_exertion_input';

class PerceivedExertionInput extends React.Component {
  state = {
    sliderDomain: [1, 10],
    showToggle: this.props.showToggle,
    hasValue: this.props.perceivedExertion !== null,
    perceivedExertion:
      this.props.perceivedExertion === null
        ? [-1]
        : [this.props.perceivedExertion],
    preferPerceivedExertion: this.props.preferPerceivedExertion
  };

  containerRef = React.createRef();

  componentDidMount() {
    // Listen to an event so that code outside of React can
    // communicate with this component.
    const node = this.containerRef.current;
    const showToggleEvent = 'PerceivedExertionInput::showToggle';
    node.addEventListener(showToggleEvent, ({ detail }) => {
      this.setState({ showToggle: detail.showToggle });
    });
  }

  handleReset = () => {
    const { analytics } = this.props;

    this.setState({
      hasValue: false,
      perceivedExertion: [-1],
      preferPerceivedExertion: false
    });

    trackV2({
      category: analytics.category,
      page: analytics.page.outer,
      action: 'click',
      element: 'remove_perceived_exertion_input'
    });
  };

  handleSliderUpdate = (v) => {
    const { perceivedExertion } = this.state;

    if (perceivedExertion[0] !== -1) {
      this.setState({ hasValue: true });
    }

    this.setState({ perceivedExertion: v });
  };

  handleSliderChange = (values) => {
    const { perceivedExertion } = this.state;
    const { analytics } = this.props;

    if (perceivedExertion[0] === -1 || perceivedExertion[0] !== values[0]) {
      return;
    }

    trackV2({
      category: analytics.category,
      page: analytics.page.outer,
      action: 'interact',
      element: 'perceived_exertion_slider',
      properties: {
        value: values[0]
      }
    });
  };

  handlePreferPerceivedExertionToggle = () => {
    const { preferPerceivedExertion } = this.state;
    const { analytics } = this.props;

    this.setState({ preferPerceivedExertion: !preferPerceivedExertion });

    trackV2({
      category: analytics.category,
      page: analytics.page.outer,
      action: 'click',
      element: 'prefer_perceived_exertion_toggle'
    });
  };

  getExertionKey = () => {
    const { hasValue, perceivedExertion } = this.state;

    if (!hasValue) {
      return 'empty';
    }

    if (perceivedExertion[0] <= 3) {
      return 'easy';
    }
    if (perceivedExertion[0] >= 4 && perceivedExertion[0] <= 6) {
      return 'moderate';
    }
    if (perceivedExertion[0] >= 7 && perceivedExertion[0] <= 9) {
      return 'hard';
    }

    return 'max_effort';
  };

  getHintBodyText = () => {
    const exertionKey = this.getExertionKey();

    if (exertionKey === 'empty') {
      return I18n.t(`${I18nPrefix}.slider.hint.${exertionKey}.body_v2`);
    }

    return (
      <>
        {I18n.t(`${I18nPrefix}.slider.hint.${exertionKey}.body_line_1`)}
        <br />
        {I18n.t(`${I18nPrefix}.slider.hint.${exertionKey}.body_line_2`)}
        <br />
        {I18n.t(`${I18nPrefix}.slider.hint.${exertionKey}.body_line_3`)}
      </>
    );
  };

  render() {
    const {
      sliderDomain,
      showToggle,
      hasValue,
      perceivedExertion,
      preferPerceivedExertion
    } = this.state;
    const { entityId, inputName, toggleName, analytics } = this.props;

    const exertionKey = this.getExertionKey();

    return (
      <div ref={this.containerRef}>
        <div className={styles.labelContainer}>
          <label
            htmlFor={`perceived-exertion-slider-${entityId}`}
            className={`text-footnote ${styles.label}`}
          >
            {I18n.t(`${I18nPrefix}.slider.label`)}
          </label>
          <Button
            type="button"
            variant="icon"
            // .help.new-version is needed here so that jQuery code can
            // pickup the click event and show the glossary modal
            className={`help new-version ${styles.infoBtn}`}
            data-glossary-term="definition-perceived-exertion"
            aria-label={I18n.t(
              `${I18nPrefix}.toggle.learn_more.cta.learn_more`
            )}
            onClick={() => {
              trackV2({
                category: analytics.category,
                page: analytics.page.outer,
                action: 'click',
                element: 'toggle_perceived_exertion_learn_more'
              });
            }}
          >
            <InfoIcon fill="#999" aria-hidden={true} />
          </Button>
        </div>
        <input
          type="hidden"
          name={inputName}
          value={hasValue ? perceivedExertion[0] : ''}
        />
        <div className={styles.sliderContainer}>
          <div className={styles.slider}>
            <div className={styles.title}>
              <div className="text-footnote">
                {I18n.t(`${I18nPrefix}.slider.title.${exertionKey}`)}
              </div>

              {hasValue && (
                <Button
                  type="button"
                  className={`text-footnote ${styles.resetBtn}`}
                  onClick={this.handleReset}
                >
                  {I18n.t(`${I18nPrefix}.slider.clear_entry`)}
                </Button>
              )}
            </div>

            <RPESlider
              entityId={entityId}
              domain={sliderDomain}
              values={perceivedExertion}
              onUpdate={this.handleSliderUpdate}
              onChange={this.handleSliderChange}
            />
            <div
              className={`text-caption2 ${styles.sliderTicks} ${
                hasValue ? '' : styles.disabled
              }`}
            >
              <div>{I18n.t(`${I18nPrefix}.slider.ticks.easy`)}</div>
              <div>{I18n.t(`${I18nPrefix}.slider.ticks.moderate`)}</div>
              <div>{I18n.t(`${I18nPrefix}.slider.ticks.max_effort`)}</div>
            </div>

            {showToggle && (
              <Toggle
                entityId={entityId}
                name={toggleName}
                checked={preferPerceivedExertion}
                disabled={!hasValue}
                onChange={this.handlePreferPerceivedExertionToggle}
              />
            )}
          </div>

          <div className={styles.hint}>
            <div className={`text-caption2 text-bold ${styles.hintTitle}`}>
              {I18n.t(`${I18nPrefix}.slider.hint.${exertionKey}.title`)}
            </div>
            <div className="text-caption2">{this.getHintBodyText()}</div>
          </div>
        </div>
      </div>
    );
  }
}

PerceivedExertionInput.defaultProps = {
  perceivedExertion: null,
  preferPerceivedExertion: false,
  // TODO: update this to false once testing is done
  showToggle: true
};

PerceivedExertionInput.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  inputName: PropTypes.string.isRequired,
  toggleName: PropTypes.string.isRequired,
  perceivedExertion: PropTypes.number,
  preferPerceivedExertion: PropTypes.bool,
  showToggle: PropTypes.bool,
  analytics: PropTypes.shape({
    category: PropTypes.string.isRequired,
    page: PropTypes.shape({
      outer: PropTypes.string,
      inner: PropTypes.string
    }).isRequired
  }).isRequired
};

export default PerceivedExertionInput;
