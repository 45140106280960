import React from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';
import Cldr from 'utils/Cldr';

import Comments from '../Comments';
import ActionsDropdown from '../ActionsDropdown';

import styles from './styles.scss';

const I18nPrefix = 'components.comments.';
const I18nKey = {
  report: `${I18nPrefix}report`,
  delete: `${I18nPrefix}delete`
};

const Post = ({
  id,
  avatar,
  authorId,
  authorName,
  timestamp,
  deletable,
  bodyText,
  comments,
  deletePost,
  reportPost,
  currentAthlete
}) => {
  const getAction = () => {
    if (deletable) {
      return { text: I18n.t(I18nKey.delete), onClick: () => deletePost(id) };
    }
    return { text: I18n.t(I18nKey.report), onClick: () => reportPost(id) };
  };
  return (
    <div className={styles.container}>
      <div className={styles.avatarWrapper}>{avatar}</div>
      <div className={styles.header}>
        <div className="text-subhead mt-0 mb-0">
          <a href={`/athletes/${authorId}`} className={styles.authorName}>
            {authorName}
          </a>
        </div>
        <div className="text-caption1 mt-0 mb-0">
          {Cldr.formatTimespan(timestamp)}
        </div>
      </div>

      {Object.keys(getAction()).length !== 0 && (
        <ActionsDropdown className={styles.actions} actions={[getAction()]} />
      )}
      <div className={styles.body}>
        <p className="text-caption1 mb-0">{bodyText}</p>
      </div>
      {(comments.length > 0 || currentAthlete) && (
        <Comments
          parentId={id}
          comments={comments}
          className={styles.comments}
          currentAthlete={currentAthlete}
        />
      )}
    </div>
  );
};

Post.defaultProps = {
  comments: [],
  currentAthlete: null
};

Post.propTypes = {
  id: PropTypes.number.isRequired,
  avatar: PropTypes.element.isRequired,
  authorId: PropTypes.number.isRequired,
  authorName: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  deletable: PropTypes.bool.isRequired,
  bodyText: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  deletePost: PropTypes.func.isRequired,
  reportPost: PropTypes.func.isRequired,
  currentAthlete: PropTypes.shape({})
};

export default Post;
