import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@strava/ui/Avatar';
import styles from './styles.scss';

import ExcludedSegmentEffortsItem from '../ExcludedSegmentEffortsItem';

const AvatarWithDataRow = ({
  data,
  achievementIcon,
  loadExcludedEfforts,
  segmentId
}) => {
  return (
    <div className={styles.rowContainer}>
      <div className={styles.rowAvatar}>
        <Avatar
          name={data.name}
          src={data.profile}
          href={`/athletes/${data.id}`}
          type="athlete"
          size="large"
          className={styles.avatarBorder}
        />
      </div>
      <div className={styles.rowData}>
        {data.name && <div>{data.name}</div>}
        <div className={styles.callOutRow}>
          {achievementIcon && (
            <div className={styles.callOutIcon}>{achievementIcon}</div>
          )}
          <ul>
            {data.stats.map((entry) => (
              <li key={entry.label}>
                <div className={styles.dataValuePair}>
                  <div className={styles.callOutAchievement}>{entry.label}</div>
                  <div
                    className={styles.callOutEffort}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: entry.value ? `- ${entry.value}` : '--'
                    }}
                  />
                </div>
              </li>
            ))}
            {loadExcludedEfforts && (
              <ExcludedSegmentEffortsItem entity="segment" id={segmentId} />
            )}
          </ul>

          {data.date && (
            <div className={`text-caption2 ${styles.callOutDate}`}>
              <a
                href={`/activities/${data.activityId}#${data.segmentEffortId}`}
              >
                {data.date}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AvatarWithDataRow.defaultProps = {
  achievementIcon: null,
  loadExcludedEfforts: false,
  segmentId: null
};

AvatarWithDataRow.propTypes = {
  achievementIcon: PropTypes.element,
  data: PropTypes.shape({
    segmentEffortId: PropTypes.number,
    activityId: PropTypes.number,
    name: PropTypes.string,
    profile: PropTypes.string,
    id: PropTypes.number,
    date: PropTypes.string,
    stats: PropTypes.array,
    segmentId: PropTypes.string
  }).isRequired,
  loadExcludedEfforts: PropTypes.bool,
  segmentId: PropTypes.number
};

export default AvatarWithDataRow;
