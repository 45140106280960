import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '@strava/ui/Spinner';
import Avatar from '@strava/ui/Avatar';

import MediaQuery, { breakpoints } from 'utils/media-query';

import Modal from 'components/shared/Modal';
import { Tabs, TabList, Tab, TabPanel } from 'components/shared/tabs';

import styles from './styles.scss';

const renderFriends = (friends) =>
  friends.map((friend) => (
    <li key={friend.name} className={styles.friend}>
      <Avatar
        href={friend.athletePath}
        size="small"
        name={friend.name}
        src={friend.avatarSrc}
        type="athlete"
      />
      <div className={styles.friendText}>
        <a href={friend.athletePath}>{friend.name}</a>
        <div>{friend.location}</div>
      </div>
    </li>
  ));

const FriendsList = ({
  modalIsOpen,
  friendsRequested,
  friends,
  closeModal,
  onModalOpen,
  challengeLogo,
  challengeName
}) => (
  <Modal
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    onAfterOpen={onModalOpen}
    dialogClassName={styles.modalDialog}
  >
    <div className={styles.modalContent}>
      <button
        className={`btn btn-unstyled ${styles.closeButton}`}
        onClick={closeModal}
      >
        <div className="app-icon icon-close icon-sm" />
      </button>
      <div className={styles.modalHeader}>
        <img
          src={challengeLogo}
          alt={challengeName}
          className={styles.challengeLogo}
        />
        {/* Bigger screens - tablets & desktop */}
        <MediaQuery minWidth={breakpoints.screenXs}>
          <h3 className={`text-title3 ${styles.challengeName}`}>
            {challengeName}
          </h3>
        </MediaQuery>

        {/* Smaller screens - mobile */}
        <MediaQuery maxWidth={breakpoints.screenXs}>
          <h3 className={`text-headline ${styles.challengeName}`}>
            {challengeName}
          </h3>
        </MediaQuery>
      </div>
      <div>
        <Tabs>
          <TabList className={styles.tabList}>
            <Tab className={styles.activeTab}>{`Friends ${
              friends ? `(${friends.length})` : ''
            }`}</Tab>
          </TabList>
          <TabPanel className={styles.tabPanel}>
            <ul>
              {friendsRequested ? (
                <li className={styles.spinner}>
                  <Spinner />
                </li>
              ) : (
                friends && renderFriends(friends)
              )}
            </ul>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  </Modal>
);

FriendsList.defaultProps = {
  friends: null
};

FriendsList.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  friendsRequested: PropTypes.bool.isRequired,
  friends: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      avatarSrc: PropTypes.string,
      athletePath: PropTypes.string,
      location: PropTypes.string
    })
  ),
  closeModal: PropTypes.func.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  challengeLogo: PropTypes.string.isRequired,
  challengeName: PropTypes.string.isRequired
};

export default FriendsList;
