import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Analytics from 'utils/analytics';

import I18n from 'utils/I18n';
import { valueChanged } from '../../actions';
import OptOutSetting from '../OptOutSetting';

import styles from './index.scss';

const I18nPrefix = 'strava.settings.partner_integrations.';
const I18nKey = {
  enhancedHeader: `${I18nPrefix}enhanced_header`,
  sponsoredHeader: `${I18nPrefix}sponsored_header`,
  yourHeader: `${I18nPrefix}your_header`
};

export class PartnerIntegrationsList extends React.Component {
  onChange = ({ optOutName, partnerName, value, partnerType }) => {
    Analytics.trackV2({
      action: 'click',
      category: 'sponsor_opt_out',
      page: 'all_sponsored_settings',
      element: value ? 'enable' : 'disable',
      properties: { partner: optOutName }
    });

    this.props.onValueChanged({
      athleteId: this.props.athleteId,
      optOutName,
      partnerName,
      partnerType,
      value: !value
    });
  };

  selected = (partnerName) => this.props.selected === partnerName;

  hasSponsored() {
    const { sponsored } = this.props;
    return Object.keys(sponsored).length > 0;
  }

  hasEnhanced() {
    const { enhanced } = this.props;
    return Object.keys(enhanced).length > 0;
  }

  showEnhancedHeading() {
    return this.hasSponsored() && this.hasEnhanced();
  }

  sponsoredMapping() {
    const { sponsored } = this.props;
    return Object.keys(sponsored).map((partner) => (
      <OptOutSetting
        key={sponsored[partner].partnerName}
        partner={sponsored[partner]}
        partnerType="sponsored"
        selected={this.selected(sponsored[partner].partnerName)}
        onChange={this.onChange}
      />
    ));
  }

  enhancedMapping() {
    const { enhanced } = this.props;
    return Object.keys(enhanced).map((partner) => (
      <OptOutSetting
        key={enhanced[partner].partnerName}
        partner={enhanced[partner]}
        partnerType="enhanced"
        selected={this.selected(enhanced[partner].partnerName)}
        onChange={this.onChange}
      />
    ));
  }

  sponsoredIntegrations() {
    if (this.hasSponsored()) {
      return (
        <div className={styles.sponsoredSection}>
          <h2>{I18n.t(I18nKey.sponsoredHeader)}</h2>
          {this.sponsoredMapping()}
        </div>
      );
    }
    return null;
  }

  enhancedIntegrations() {
    if (this.hasEnhanced()) {
      if (this.showEnhancedHeading()) {
        return (
          <div className={styles.enhancedHeader}>
            <h2>{I18n.t(I18nKey.yourHeader)}</h2>
            {this.enhancedMapping()}
          </div>
        );
      }
      return <div>{this.enhancedMapping()}</div>;
    }
    return null;
  }

  render() {
    return (
      <div>
        {this.sponsoredIntegrations()}
        {this.enhancedIntegrations()}
      </div>
    );
  }
}

PartnerIntegrationsList.defaultProps = {
  selected: null
};

PartnerIntegrationsList.propTypes = {
  athleteId: PropTypes.number.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  selected: PropTypes.string,
  sponsored: PropTypes.shape({}).isRequired,
  enhanced: PropTypes.shape({}).isRequired
};

export default connect(
  ({ athleteId, sponsored, enhanced, selected }) => ({
    athleteId,
    sponsored,
    enhanced,
    selected
  }),
  { onValueChanged: valueChanged }
)(PartnerIntegrationsList);
