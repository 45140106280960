import React, { useState } from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';
import { isMobile, dateYearsAgo } from 'utils/generalUtils';
import { YOUNGEST_AGE_ON_PLATFORM } from 'constants/appConstants';
import { isNil } from 'lodash-es';
import styles from './styles.scss';
import StravaDropdownSelect from '../StravaDropdownSelect/StravaDropdownSelect';

const MIN_YEARS_BEFORE_TODAY = 125;

const I18N_MONTHS = (() => {
  const prefix = 'templates.settings.months.';
  return [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec'
  ].map((m) => prefix + m);
})();

const DropdownDatePicker = ({
  className,
  dateMax,
  dateSelected,
  dateChangeCallback,
  erroredControls,
  required,
  disabled,
  locale,
  focusTarget,
  enableDefaultFocus
}) => {
  // IMPORTANT - using defaultProps does not reflect the mocked global system date
  // that unit tests need for this component uses.
  // ie. new Date() initiated using defaultProps is always the current date while
  // new Date() in the mounted react would be the mocked date.
  //
  // Setting a custom date during tests if very important for this component since
  // we need to be able to test specific scenarios at any time. Because of this need,
  // maxDate default is being handled inside the mounted component.
  // Also, reactjs seems to be on the path to deprecate defaultProps - https://github.com/jsx-eslint/eslint-plugin-react/issues/2396
  // so this approach aligns more with how the future of reactjs will look like.
  const correctedDateMax = isNil(dateMax)
    ? dateYearsAgo(YOUNGEST_AGE_ON_PLATFORM)
    : dateMax;

  const [day, setDay] = useState(dateSelected ? dateSelected.getDate() : null);
  const [month, setMonth] = useState(
    dateSelected ? dateSelected.getMonth() + 1 : null
  ); // UI January => numeric value 1
  const [year, setYear] = useState(
    dateSelected ? dateSelected.getFullYear() : null
  );

  const yearMax = correctedDateMax.getFullYear();
  const monthMax = correctedDateMax.getMonth() + 1; // UI January => numeric value is 1

  // DD
  const daySelectDefault = I18n.t(
    'templates.settings.birthday_select_defaults.day'
  );
  // MM or MONTH
  const monthSelectDefault = isMobile()
    ? I18n.t('templates.settings.birthday_select_defaults.month')
    : I18n.t('templates.settings.birthday_select_defaults.month_full');
  // YYYY
  const yearSelectDefault = I18n.t(
    'templates.settings.birthday_select_defaults.year'
  );

  /**
   *  Helper function
   *  given, min =5, max =7 returns array [5,6,7]
   */
  const intArray = (min, max) =>
    Array.from({ length: max - min + 1 }, (_, i) => i + min);

  /**
   * Number in days in a given month for a specific year.
   */
  const numDaysInMonth = (y, m) => new Date(y, m, 0).getDate();

  const blankOption = { label: '', value: '' };

  /**
   * Day options that an athlete can choose from the day dropdown
   */
  const dayOptions = (() => {
    const options = required ? [] : [blankOption];
    intArray(
      1,
      year === yearMax && month === monthMax
        ? correctedDateMax.getDate()
        : numDaysInMonth(year, month)
    ).forEach((d) => {
      options.push({ label: d.toString(), value: d });
    });
    return options;
  })();

  /**
   * Localized Month options that an athlete can choose from the month dropdown
   *
   * Note: January => 1 and December => 12, Unlike JS Date where January => 0
   */
  const monthOptions = (() => {
    const options = required ? [] : [blankOption];
    intArray(1, year === yearMax ? monthMax : 12).forEach((m) => {
      const label = isMobile() ? m.toString() : I18n.t(I18N_MONTHS[m - 1]);
      options.push({ label, value: m });
    });
    return options;
  })();

  /**
   * Year options that an athlete can choose from the year dropdown
   */
  const yearOptions = (() => {
    const options = required ? [] : [blankOption];
    intArray(
      correctedDateMax.getFullYear() - MIN_YEARS_BEFORE_TODAY,
      correctedDateMax.getFullYear()
    )
      .reverse() // descending order - current year first
      .forEach((y) => {
        options.push({ label: y.toString(), value: y });
      });
    return options;
  })();

  const handleYearChange = (option) => {
    const newYear = option.value;
    setYear(newYear);
    let newDay = day;
    let newMonth = month;
    if (day > numDaysInMonth(newYear, month)) {
      newDay = null;
      setDay(newDay); // clear out the selected day
    }
    if (newYear === yearMax && month > monthMax) {
      newMonth = null;
      setMonth(newMonth); // clear out the month field (this avoids dates that are before the dateMax from being entered)
    }
    dateChangeCallback({ month: newMonth, day: newDay, year: newYear });
  };

  const handleMonthChange = (option) => {
    const newMonth = option.value;
    setMonth(newMonth);
    let newDay = day;
    if (day > numDaysInMonth(year, newMonth)) {
      newDay = null;
      setDay(newDay); // clear out the selected day
    }
    dateChangeCallback({ month: newMonth, day: newDay, year });
  };

  const handleDayChange = (option) => {
    const newDay = option.value;
    setDay(newDay);
    dateChangeCallback({ month, day: newDay, year });
  };

  /**
   * The date control that gains focus
   */
  const defaultFocus = (() => {
    if (enableDefaultFocus) {
      switch (locale) {
        case 'en-US':
          return 'month';
        case 'ko-KR':
        case 'ja-JP':
          return 'year';
        default:
          return 'day';
      }
    }
    return null;
  })();

  const monthSelector = (
    <StravaDropdownSelect
      disabled={disabled}
      key="month"
      name="month"
      className={styles.month}
      options={monthOptions}
      value={monthOptions.find((o) => o.value === month)}
      onChange={handleMonthChange}
      placeholder={monthSelectDefault}
      ariaLabel={monthSelectDefault}
      data-cy="month"
      hasError={erroredControls.includes('month')}
      isFocused={[focusTarget, defaultFocus].includes('month')}
    />
  );

  const daySelector = (
    <StravaDropdownSelect
      disabled={disabled}
      key="day"
      name="day"
      className={styles.day}
      options={dayOptions}
      value={dayOptions.find((o) => o.value === day)}
      onChange={handleDayChange}
      placeholder={daySelectDefault}
      ariaLabel={daySelectDefault}
      hasError={erroredControls.includes('day')}
      isFocused={[focusTarget, defaultFocus].includes('day')}
    />
  );

  const yearSelector = (
    <StravaDropdownSelect
      disabled={disabled}
      key="year"
      name="year"
      className={styles.year}
      options={yearOptions}
      value={yearOptions.find((o) => o.value === year)}
      onChange={handleYearChange}
      placeholder={yearSelectDefault}
      ariaLabel={yearSelectDefault}
      hasError={erroredControls.includes('year')}
      isFocused={[focusTarget, defaultFocus].includes('year')}
    />
  );

  /**
   * Determines date layout based on the language
   */
  const buildSelectors = () => {
    switch (locale) {
      case 'en-US':
        return [monthSelector, daySelector, yearSelector];
      case 'ko-KR':
      case 'ja-JP':
        return [yearSelector, monthSelector, daySelector];
      default:
        return [daySelector, monthSelector, yearSelector];
    }
  };

  const classNames = [styles.container];
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')} data-testid="date-picker-container">
      {buildSelectors()}
    </div>
  );
};

DropdownDatePicker.defaultProps = {
  className: null,
  dateMax: null,
  dateSelected: null,
  erroredControls: [],
  required: false,
  locale: window.navigator.userLanguage || window.navigator.language,
  disabled: false,
  focusTarget: null,
  enableDefaultFocus: false
};

DropdownDatePicker.propTypes = {
  className: PropTypes.string,
  dateMax: PropTypes.instanceOf(Date),
  dateSelected: PropTypes.instanceOf(Date),
  dateChangeCallback: PropTypes.func.isRequired,
  erroredControls: PropTypes.arrayOf(PropTypes.oneOf(['day', 'month', 'year'])),
  locale: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  focusTarget: PropTypes.string,
  enableDefaultFocus: PropTypes.bool
};

export default DropdownDatePicker;
