import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@strava/ui/Button';
import Spinner from '@strava/ui/Spinner';
import Avatar from '@strava/ui/Avatar';

import Cldr from 'utils/Cldr';
import I18n from 'utils/I18n';

import styles from './styles.scss';
import { joinClub } from '../../actions';

export const I18nPrefix = 'strava.challenges.challenge_detail.club';

export const memberCountString = (count) => {
  const localeCount = Cldr.formatDecimal(count);
  const suffix = count > 1 ? 'many' : 'one';
  return I18n.t(`${I18nPrefix}.athletes.${suffix}`, { number: localeCount });
};

export class Club extends React.Component {
  onJoinClub = () => this.props.onJoinClub(this.props.club.joinUrl);

  joinButton = (isClubMember, clubJoinRequested) => (
    <Button
      variant="primaryOutline"
      disabled={isClubMember || clubJoinRequested}
      className={`btn-sm btn-block text-footnote ${styles.button}`}
      onClick={this.onJoinClub}
    >
      {clubJoinRequested ? (
        <Spinner />
      ) : (
        I18n.t(`${I18nPrefix}.${isClubMember ? 'joined' : 'join'}`)
      )}
    </Button>
  );

  render() {
    const { clubJoinRequested, club } = this.props;
    const {
      name,
      sportType,
      isClubMember,
      clubLogoUrl,
      memberCount,
      clubUrl
    } = club;

    return (
      <div className={styles.container}>
        <Avatar
          name={name}
          type="club"
          src={clubLogoUrl}
          className={styles.logo}
          href={clubUrl}
        />
        <p className={`text-caption3 ${styles.organizing}`}>
          {I18n.t(`${I18nPrefix}.organizing_club`)}
        </p>
        <h3 className={`text-title3 ${styles.name}`}>
          <a href={clubUrl}>{name}</a>
        </h3>
        <div className={styles.info}>
          <span className="app-icon-wrapper">
            <span className={`app-icon icon-dark icon-sm icon-${sportType}`} />
          </span>
          <span className={`text-footnote ${styles.memberCount}`}>
            {memberCountString(memberCount)}
          </span>
        </div>
        {this.joinButton(isClubMember, clubJoinRequested)}
      </div>
    );
  }
}

Club.defaultProps = {
  clubJoinRequested: false
};

Club.propTypes = {
  onJoinClub: PropTypes.func.isRequired,
  clubJoinRequested: PropTypes.bool,
  club: PropTypes.shape({
    name: PropTypes.string.isRequired,
    sportType: PropTypes.string.isRequired,
    isClubMember: PropTypes.bool.isRequired,
    clubLogoUrl: PropTypes.string.isRequired,
    memberCount: PropTypes.number.isRequired,
    clubUrl: PropTypes.string.isRequired,
    joinUrl: PropTypes.string.isRequired
  }).isRequired
};

export default connect(
  ({ club, clubJoinRequested }) => ({
    clubJoinRequested,
    club: {
      name: club.name,
      sportType: club.sportType,
      isClubMember: club.isClubMember,
      clubLogoUrl: club.clubLogoUrl,
      memberCount: club.memberCount,
      clubUrl: club.clubUrl,
      joinUrl: club.joinUrl
    }
  }),
  { onJoinClub: joinClub }
)(Club);
