import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { trackV2 } from 'utils/analytics';
import StravaMentionsInput from '../StravaMentionsInput/StravaMentionsInput';
import styles from './index.scss';

function ActivityDescriptionEdit({
  entity,
  entityId,
  placeHolder,
  activityDescription,
  clubMentionsEnabled,
  suggestionLimit,
  inputName,
  autoFocus,
  viewingAthleteId,
  analyticsFields
}) {
  // Avoids text duplication bug detailed here: https://github.com/signavio/react-mentions/issues/332
  const cleanDescription = activityDescription
    ? activityDescription.replace(/[\r]+/g, '')
    : '';
  const [description, setDescription] = useState(cleanDescription);

  const surfaceType = 'description';

  const handleChange = ({ newValue }) => {
    // Avoids text duplication bug detailed here: https://github.com/signavio/react-mentions/issues/332
    const cleanValue = newValue.replace(/[\r]+/g, '');
    setDescription(cleanValue);
  };

  const descriptionOnClick = () => {
    if (analyticsFields) {
      trackV2({
        category: analyticsFields.category,
        page: analyticsFields.page,
        action: 'click',
        element: surfaceType
      });
    }
  };

  return (
    <>
      <StravaMentionsInput
        autoFocus={autoFocus}
        entity={entity}
        entityId={entityId ? entityId.toString() : null}
        onInputChange={handleChange}
        clubMentionsEnabled={clubMentionsEnabled}
        inputName={inputName}
        placeHolder={placeHolder}
        suggestionLimit={suggestionLimit}
        viewingAthleteId={viewingAthleteId.toString()}
        clearInputToggle={false}
        inputHeight={108}
        onInputClickAnalytics={descriptionOnClick}
        surfaceType={surfaceType}
        analyticsFields={analyticsFields}
        value={description || ''}
        classNames={styles}
      />
    </>
  );
}

ActivityDescriptionEdit.propTypes = {
  entity: PropTypes.string.isRequired,
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeHolder: PropTypes.string,
  activityDescription: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  clubMentionsEnabled: PropTypes.bool.isRequired,
  suggestionLimit: PropTypes.number,
  autoFocus: PropTypes.bool,
  viewingAthleteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  analyticsFields: PropTypes.shape({
    page: PropTypes.string,
    category: PropTypes.string
  })
};

ActivityDescriptionEdit.defaultProps = {
  placeHolder: null,
  suggestionLimit: 5,
  entityId: null,
  activityDescription: '',
  autoFocus: false,
  analyticsFields: null
};

export default ActivityDescriptionEdit;
