import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Interweave } from 'interweave';
import { UrlMatcher } from 'interweave-autolink';

import Lightbox from '@strava/react-image-lightbox';
import '@strava/react-image-lightbox/style.css';
import Button from '@strava/ui/Button';
import {
  DropdownMenu,
  DropdownButton,
  DropdownPopover,
  DropdownItems,
  DropdownLink,
  DropdownItem
} from '@strava/ui/Dropdown';

import NavigationMoreHighlightedXsmall from '@strava/icons/NavigationMoreHighlightedXsmall';
import ActionsEditNormalXsmall from '@strava/icons/ActionsEditNormalXsmall';

import createNetworkingClient from 'utils/networking-client';
import I18n from 'utils/I18n';
import { trackV2 } from 'utils/analytics';

import ReportMediaModal from './components/ReportMediaModal';
import DescriptionEditModal from './components/DescriptionEditModal';
import styles from './StravaMediaLightbox.scss';
import { logError } from '../../utils/sentry';

import Poc from '../Poc';

const lightboxCustomStyles = {
  overlay: {
    zIndex: '4000' // Modals on top of this lightbox are set to 5000
  }
};

const StravaMediaLightbox = ({
  selectedPhotoIndex,
  photoList,
  onMount,
  onCloseRequest,
  analyticProps
}) => {
  const [params, setParams] = useState({
    photoIndex: selectedPhotoIndex,
    isSavingDescription: false,
    isReportingMedia: false,
    openEditDescriptionModal: false,
    openReportMediaModal: false,
    showReportPhotoButton: false,
    updatedDescription: '',
    reportedMediaHash: {}
    // reported status is kept in-memory
    // on refresh, phoots-service does not include reported media in the results
  });
  const [video, setVideo] = useState();
  const { entityId, page, feedType } = analyticProps;

  useEffect(() => {
    onMount();

    const lockWheelScrolling = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const lockKeyScrolling = (e) => {
      if (['ArrowUp', 'ArrowDown'].indexOf(e.code) > -1) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    if (document) {
      document.addEventListener('wheel', lockWheelScrolling, {
        passive: false
      });
      document.addEventListener('keydown', lockKeyScrolling, false);
    }

    return () => {
      if (document) {
        document.removeEventListener('wheel', lockWheelScrolling, {
          passive: false
        });
        document.removeEventListener('keydown', lockKeyScrolling, false);
      }
    };
  }, []);

  // Analytics for screen enter
  useEffect(() => {
    if (photoList.length === 0) {
      return;
    }
    const selectedMedia = photoList[selectedPhotoIndex];
    const isImage = selectedMedia.media_type === 1;

    const data = {
      category: 'media',
      page: 'lightbox',
      properties: {
        source: feedType,
        entity_id: entityId,
        entity_type: page,
        element_entity_type: selectedMedia.media_type,
        element_entity_id: selectedMedia.photo_id
      }
    };

    // Lightbox Screen Enter Photo / Video
    trackV2({
      ...data,
      action: 'screen_enter',
      element: isImage ? 'photo' : 'video'
    });
  }, []);

  const photoCount = photoList.length;
  const prevPhotoIndex = (params.photoIndex + photoCount - 1) % photoCount;
  const nextPhotoIndex = (params.photoIndex + 1) % photoCount;

  const currentPhoto = photoList[params.photoIndex];
  const prevPhoto = photoList[prevPhotoIndex];
  const nextPhoto = photoList[nextPhotoIndex];
  const onMoveNextRequest = useCallback(
    () =>
      setParams((prevParams) => ({
        ...prevParams,
        photoIndex: nextPhotoIndex
      })),
    [nextPhotoIndex]
  );
  const onMovePrevRequest = useCallback(
    () =>
      setParams((prevParams) => ({
        ...prevParams,
        photoIndex: prevPhotoIndex
      })),
    [prevPhotoIndex]
  );

  const toggleReportMediaButton = useCallback(
    () =>
      setParams((prevState) => ({
        ...params,
        showReportPhotoButton: !prevState.showReportPhotoButton,
        openReportPhotoModal: false
      })),
    []
  );

  const reportMedia = (reason) => {
    setParams({ ...params, isReportingMedia: true });
    const url = `/media/${currentPhoto.media_type}/${currentPhoto.photo_id}`;
    const payload = {
      report_reason: reason,
      owner_id: currentPhoto.owner_id
    };
    createNetworkingClient()
      .put(url, payload)
      .then(() => {
        const updatedReportedMediaHash = params.reportedMediaHash;
        updatedReportedMediaHash[currentPhoto.photo_id] = true;
        setParams({
          ...params,
          isReportingMedia: false,
          reportedMediaHash: updatedReportedMediaHash
        });
      })
      .catch((e) => {
        logError(e);
        const updatedReportedMediaHash = params.reportedMediaHash;
        updatedReportedMediaHash[currentPhoto.photo_id] = false;
        setParams({
          ...params,
          isReportingMedia: false,
          reportedMediaHash: updatedReportedMediaHash
        });
      });
  };

  const updateDescription = () => {
    setParams({ ...params, isSavingDescription: true });
    const url = `/media/${currentPhoto.media_type}/${currentPhoto.photo_id}/add_caption`;
    const payload = {
      caption: params.updatedDescription
    };
    createNetworkingClient()
      .put(url, payload)
      .then(() => {
        // Update the caption in-memory
        // eslint-disable-next-line no-param-reassign
        currentPhoto.caption_escaped = params.updatedDescription;
        setParams({
          ...params,
          isSavingDescription: false,
          openEditDescriptionModal: false,
          updatedDescription: ''
        });
      })
      .catch((e) => {
        logError(e);
        setParams({
          ...params,
          isSavingDescription: false
        });
      });
  };

  const addOrEditDescriptionLabel = () =>
    currentPhoto.caption_escaped
      ? I18n.t('templates.feed.activity.edit_description_label')
      : I18n.t('templates.feed.activity.add_description_label');

  const pause = () => {
    if (video && !video.paused()) {
      video.pause();
    }
  };

  const openAddEditDescriptionModal = useCallback(() => {
    setParams((prevParams) => ({
      ...prevParams,
      openEditDescriptionModal: true
    }));
    pause();
  }, [video]);

  const openReportMediaModal = useCallback(() => {
    setParams({
      ...params,
      openReportMediaModal: true
    });
    pause();
  }, [video]);

  const toolbarButtons = useCallback(() => {
    const buttons = [];
    const isReportable =
      currentPhoto.viewing_athlete_id !== currentPhoto.owner_id;
    const isEditable = currentPhoto.editable;
    const isNotOnActivityDetailPage =
      currentPhoto.activity &&
      currentPhoto.activity_id &&
      window.location.pathname !== `/activities/${currentPhoto.activity_id}`;

    buttons.push(
      <DropdownMenu>
        <DropdownButton
          className={styles.moreOptionsDropdownButton}
          data-testid="more_options_button"
        >
          <NavigationMoreHighlightedXsmall />
        </DropdownButton>
        <DropdownPopover
          className={styles.moreOptionsDropdownList}
          portal={true}
        >
          <DropdownItems>
            {/* Go to Activity */}
            {isNotOnActivityDetailPage && (
              <DropdownLink
                href={`/activities/${currentPhoto.activity_id}`}
                className={styles.dropdownItem}
                data-testid="go_to_activity"
                onClick={() => {
                  trackV2({
                    category: 'media',
                    page: 'lightbox_overflow',
                    action: 'click',
                    element: 'go_to_activity',
                    properties: {
                      source: feedType,
                      entity_id: entityId,
                      entity_type: page,
                      element_entity_type: currentPhoto.media_type,
                      element_entity_id: currentPhoto.photo_id
                    }
                  });
                }}
              >
                {I18n.t(
                  'strava.external_photos.photostream_lightbox_view.go_to_activity_v2'
                )}
              </DropdownLink>
            )}
            {/* Report Media */}
            {isReportable && (
              <DropdownItem
                className={styles.dropdownItem}
                onClick={() => {
                  openReportMediaModal();
                  trackV2({
                    category: 'media',
                    page: 'lightbox_overflow',
                    action: 'click',
                    element: 'report_media',
                    properties: {
                      source: feedType,
                      entity_id: entityId,
                      entity_type: page,
                      element_entity_type: currentPhoto.media_type,
                      element_entity_id: currentPhoto.photo_id
                    }
                  });
                }}
                data-testid="report_photo_button"
              >
                {I18n.t('templates.feed.activity.report_photo.report_media')}
              </DropdownItem>
            )}
            {/* Add or Edit Description for Media */}
            {isEditable && (
              <DropdownItem
                className={styles.dropdownItem}
                onClick={() => {
                  openAddEditDescriptionModal();
                  trackV2({
                    category: 'media',
                    page: 'lightbox_overflow',
                    action: 'click',
                    element: 'description',
                    properties: {
                      source: feedType,
                      entity_id: entityId,
                      entity_type: page,
                      element_entity_type: currentPhoto.media_type,
                      element_entity_id: currentPhoto.photo_id,
                      cta_copy: addOrEditDescriptionLabel()
                    }
                  });
                }}
                data-testid="edit-description-toolbar-button"
              >
                {addOrEditDescriptionLabel()}
              </DropdownItem>
            )}
          </DropdownItems>
        </DropdownPopover>
      </DropdownMenu>
    );

    return buttons;
  }, [currentPhoto, video]);

  const imageDescription = useCallback(() => {
    return (
      <div className={styles.descriptionBox}>
        <div className={styles.side} />
        {currentPhoto.caption_escaped && (
          <div className={styles.description} data-testid="photo-description">
            <Interweave
              noWrap={true}
              content={currentPhoto.caption_escaped}
              matchers={[
                new UrlMatcher('url', {
                  customTLDs: ['link'] // For Branch links
                })
              ]}
            />
          </div>
        )}
        {!currentPhoto.caption_escaped &&
          currentPhoto.viewing_athlete_id === currentPhoto.owner_id && (
            <Button
              className={styles.addDescriptionBarButton}
              data-testid="add-description-bar-button"
              onClick={() => {
                openAddEditDescriptionModal();
                trackV2({
                  category: 'media',
                  page: 'lightbox',
                  action: 'click',
                  element: 'description',
                  properties: {
                    source: feedType,
                    entity_id: entityId,
                    entity_type: page,
                    element_entity_type: currentPhoto.media_type,
                    element_entity_id: currentPhoto.photo_id,
                    cta_copy: addOrEditDescriptionLabel()
                  }
                });
              }}
              type="button"
            >
              {addOrEditDescriptionLabel()}
            </Button>
          )}
        <div className={styles.side}>
          {currentPhoto.caption_escaped &&
            currentPhoto.viewing_athlete_id === currentPhoto.owner_id && (
              <Button
                className={styles.editDescriptionBarButton}
                data-testid="edit-description-bar-button"
                onClick={() => {
                  openAddEditDescriptionModal();
                  trackV2({
                    category: 'media',
                    page: 'lightbox',
                    action: 'click',
                    element: 'description',
                    properties: {
                      source: feedType,
                      entity_id: entityId,
                      entity_type: page,
                      element_entity_type: currentPhoto.media_type,
                      element_entity_id: currentPhoto.photo_id,
                      cta_copy: addOrEditDescriptionLabel()
                    }
                  });
                }}
                variant="icon"
                type="button"
              >
                <ActionsEditNormalXsmall />
              </Button>
            )}
        </div>
      </div>
    );
  }, [currentPhoto, video]);

  function handleVolumeEvent(player) {
    trackV2({
      category: 'media',
      page: player.isFullscreen() ? 'video_full_screen_player' : 'lightbox',
      action: 'click',
      properties: {
        source: feedType,
        source_type: 'activity',
        source_id: currentPhoto.activity_id,
        video_id: currentPhoto.photo_id,
        cta: player.muted() ? 'muted' : 'unmute'
      }
    });
  }

  function handleFullScreenEvent(player) {
    // Screen Enter / Exit
    trackV2({
      category: 'media',
      page: 'video_full_screen_player',
      action: player.isFullscreen() ? 'screen_enter' : 'screen_exit',
      properties: {
        source: feedType,
        source_type: 'activity',
        source_id: currentPhoto.activity_id,
        video_id: currentPhoto.photo_id
      }
    });

    // Click Event
    trackV2({
      category: 'media',
      page: 'lightbox',
      action: 'click',
      element: 'full_screen',
      properties: {
        source: feedType,
        source_type: 'activity',
        source_id: currentPhoto.activity_id,
        video_id: currentPhoto.photo_id,
        cta: player.isFullscreen()
          ? 'enter_full_screen_view'
          : 'exit_full_screen_view'
      }
    });
  }

  const isVideo = currentPhoto && currentPhoto.media_type === 2;
  const currentVideo = useCallback(
    isVideo ? (
      <React.Suspense fallback={<div />}>
        <Poc
          media={currentPhoto}
          onReady={(player) => {
            setVideo(player);
            player.on('volumechange', () => handleVolumeEvent(player));
            player.on('fullscreenchange', () => handleFullScreenEvent(player));
          }}
          options={{ autoplay: true, fluid: false, responsive: false }}
          className={styles.video}
        />
      </React.Suspense>
    ) : (
      undefined
    ),
    [currentPhoto]
  );

  return (
    <>
      {currentPhoto && (
        <div data-testid="light-box">
          <DescriptionEditModal
            isOpen={params.openEditDescriptionModal}
            isSaving={params.isSavingDescription}
            description={currentPhoto.caption_escaped}
            onDismiss={() =>
              setParams({ ...params, openEditDescriptionModal: false })
            }
            onSubmit={updateDescription}
            onTextChange={(descriptionText) => {
              params.updatedDescription = descriptionText;
            }}
            title={addOrEditDescriptionLabel()}
            analyticProps={{ currentPhoto, ...analyticProps }}
          />
          <ReportMediaModal
            isReported={params.reportedMediaHash[currentPhoto.photo_id]}
            isReporting={params.isReportingMedia}
            isOpen={params.openReportMediaModal}
            onDismiss={() => {
              setParams({ ...params, openReportMediaModal: false });
              toggleReportMediaButton();
            }}
            onSubmit={(reason) => {
              reportMedia(reason);
            }}
          />

          <Lightbox
            key="light_box"
            wrapperClassName={styles.lightboxWrapper}
            mainCustomContent={isVideo ? currentVideo : undefined}
            mainSrc={!isVideo ? currentPhoto.large : undefined}
            nextSrc={photoCount > 1 ? nextPhoto.large : ''} // no next photo if activity only has one photo
            prevSrc={photoCount > 1 ? prevPhoto.large : ''} // no previous photo if activity only has one photo
            onCloseRequest={() => {
              onCloseRequest();
              const currentMedia = isVideo ? currentVideo : currentPhoto;
              // Lightbox Screen Exit Photo / Video
              trackV2({
                category: 'media',
                page: 'lightbox',
                properties: {
                  source: feedType,
                  entity_id: entityId,
                  entity_type: page,
                  element_entity_type: currentMedia.media_type,
                  element_entity_id: currentMedia.photo_id
                },
                action: 'screen_exit',
                element: currentMedia.media_type === 1 ? 'photo' : 'video'
              });
            }}
            onMovePrevRequest={onMovePrevRequest}
            onMoveNextRequest={onMoveNextRequest}
            imageCaption={imageDescription()}
            imagePadding={80}
            reactModalStyle={lightboxCustomStyles}
            toolbarButtons={toolbarButtons()}
            enableZoom={!isVideo}
          />
        </div>
      )}
    </>
  );
};

StravaMediaLightbox.defaultProps = {
  onMount: () => {},
  analyticProps: undefined
};

StravaMediaLightbox.propTypes = {
  selectedPhotoIndex: PropTypes.number.isRequired,
  photoList: PropTypes.arrayOf(
    PropTypes.shape({
      large: PropTypes.string.isRequired,
      caption_escaped: PropTypes.string,
      photo_id: PropTypes.string.isRequired,
      viewing_athlete_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      owner_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      editable: PropTypes.bool,
      media_type: PropTypes.number
    })
  ).isRequired,
  onMount: PropTypes.func,
  onCloseRequest: PropTypes.func.isRequired,
  analyticProps: PropTypes.shape({
    entityId: PropTypes.string,
    page: PropTypes.string,
    feedType: PropTypes.string
  })
};

export default StravaMediaLightbox;
