import React from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';

import I18n from 'utils/I18n';

import styles from './styles.scss';

const I18nPrefix = 'publishes.wizard';

function StepActions({
  onCancel,
  onNext,
  disabled,
  actionType,
  actionVariant
}) {
  return (
    <div className={styles.actions}>
      <Button
        type={actionType}
        variant={actionVariant === 'next' ? 'default' : 'primary'}
        className={styles.next}
        onClick={onNext}
        disabled={disabled}
      >
        {I18n.t(`${I18nPrefix}.${actionVariant}`)}
      </Button>
      <span className={styles.or}>{I18n.t(`${I18nPrefix}.or`)}</span>
      <Button type="button" className={styles.cancel} onClick={onCancel}>
        {I18n.t(`${I18nPrefix}.cancel`)}
      </Button>
    </div>
  );
}

StepActions.defaultProps = {
  disabled: false,
  actionType: 'button',
  actionVariant: 'next',
  onNext: () => {}
};

StepActions.propTypes = {
  disabled: PropTypes.bool,
  actionType: PropTypes.oneOf(['button', 'submit']),
  actionVariant: PropTypes.oneOf(['next', 'save', 'create']),
  onNext: PropTypes.func,
  onCancel: PropTypes.func.isRequired
};

export default StepActions;
