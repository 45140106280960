import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import I18n from 'utils/I18n';
import ActionsPhotoNormalMedium from '@strava/icons/ActionsPhotoNormalMedium';
import { devLog } from 'utils/devUtils';
import cx from '@strava/ui/clsx';
import { color } from '@strava/ui-tokens/js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Popup from '@strava/ui/Popup';
import { trackV2 } from 'utils/analytics';
import FeatureEdInfoBox from './FeatureEdInfoBox';
import styles from './MediaUploader.scss';
import Thumbnail from './Thumbnail';
import FeatureEdInfoIcon from './FeatureEdInfoIcon';
import AddMediaThumbnail from './AddMediaThumbnail';
import { getPhotoWithMaxDimension } from '../../utils/generalUtils';
import {
  getThumbnailPreviewUrl,
  getMediaType,
  getUuid,
  normalizeId,
  normalizeMediaType,
  getMediaUrl,
  storeMedia,
  trimVideo,
  convertHEICFileToBlob,
  isHEICFile,
  countPhotoAndVideoFiles
} from './MediaUploader.utils';
import FeatureEdPopup from './FeatureEdPopup';

function MediaUploader({
  athleteId,
  media,
  defaultPhotoId,
  viewFeatureEdData,
  activityId
}) {
  const [files, setFiles] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const [highlightMediaId, setHighlightMediaId] = useState(defaultPhotoId);
  const firstThumbnail = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hasSeenFeatureEd, setHasSeenFeatureEd] = useState(false);
  const { count } = viewFeatureEdData;
  const canViewFeatureEd = count < 4;

  async function processFile(file) {
    const uuid = getUuid();
    const mediaType = getMediaType(file);
    // We want a place holder for HEIC files
    const transparentGifPlaceholder =
      'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
    const previewUrl = isHEICFile(file)
      ? transparentGifPlaceholder
      : await getThumbnailPreviewUrl(file);

    // Instantiate the loading state of the file
    // for the circular progress bar
    setLoadingStates((prevState) => ({
      ...prevState,
      [uuid]: 0
    }));

    setFiles((prevState) => {
      return [
        ...prevState,
        ...{
          preview: previewUrl,
          uuid,
          mediaType,
          name: file.name,
          type: file.type
        }
      ];
    });

    let tempFile = file;

    if (isHEICFile(tempFile)) {
      const newBlob = await convertHEICFileToBlob(tempFile);
      const newUrl = URL.createObjectURL(newBlob);
      tempFile = new File([newBlob], tempFile.name, {
        type: 'image/jpeg'
      });

      // Find file and replace with updated thumbnail preview
      setFiles((prevState) => {
        const tempArrayElement = prevState.find((e) => e.uuid === uuid);
        const remaining = prevState.filter((e) => e.uuid !== uuid);

        return [
          ...remaining,
          ...{
            ...tempArrayElement,
            preview: newUrl
          }
        ];
      });
    }

    const mediaUrl = await getMediaUrl(tempFile, uuid, athleteId);
    const fileToUpload = mediaType === 2 ? await trimVideo(tempFile) : tempFile;

    // Store the media to s3 and update the progress bar live
    const processEnd = await storeMedia(
      mediaUrl.data,
      fileToUpload,
      (progress) => {
        setLoadingStates((prevState) => ({
          ...prevState,
          [uuid]: progress
        }));
      }
    );

    // Upload failed
    if ('error' in processEnd) {
      setLoadingStates((prevState) => ({
        ...prevState,
        [uuid]: -1
      }));
    }
  }

  // Dropzone
  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    accept: ['image/*', 'video/*', 'image/heic'],
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach(async (file) => {
        processFile(file);
      });

      const { total, type } = countPhotoAndVideoFiles(acceptedFiles);
      trackV2({
        category: 'edit_activity',
        page: 'edit_activity',
        action: 'interact',
        element: 'add_media',
        properties: {
          source: 'main',
          type,
          stage: 'default',
          num_media: total
        }
      });

      if (!hasSeenFeatureEd) {
        setShowTooltip(true);
        setHasSeenFeatureEd(true);
      }
    },
    maxSize: 1000000000, // 1 gb
    onError: (error) => {
      // TODO: Make a better error handling
      devLog(error.message);
    }
  });

  const setHighlightMedia = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    devLog(index);
    const newId = normalizeId(files[index]);
    setHighlightMediaId(newId);
  };

  const remove = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    const newFiles = [...files];
    const newId = normalizeId(newFiles[index]);
    newFiles.splice(index, 1);
    if (highlightMediaId === newId && newFiles.length > 0) {
      setHighlightMediaId(normalizeId(newFiles[0]));
    }
    setFiles(newFiles);
  };

  useEffect(() => {
    // Sets the first media item uploaded to be highlighted
    // on initial upload
    if (highlightMediaId === null && files.length > 0) {
      setHighlightMediaId(normalizeId(files[0]));
    }
  }, [files]);

  useEffect(() => {
    // Load previews of media that are already attached to activity
    setFiles([
      ...media.map((file) => {
        setLoadingStates((prevState) => ({
          ...prevState,
          [normalizeId(file)]: 100
        }));
        return Object.assign(file, {
          preview: getPhotoWithMaxDimension(file.urls)
        });
      })
    ]);

    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const moveThumbnail = useCallback((dragIndex, hoverIndex) => {
    setFiles((prevState) => {
      const thumb = prevState[dragIndex];
      prevState.splice(dragIndex, 1);
      prevState.splice(hoverIndex, 0, thumb);

      return [...prevState];
    });
  }, []);

  return (
    <div>
      <div className={styles.label} id="patar">
        {I18n.t(`templates.activities.edit.media.header`)}
        <FeatureEdInfoIcon />
      </div>
      {canViewFeatureEd && <FeatureEdInfoBox />}
      <Popup
        contents={
          <FeatureEdPopup
            steps={[
              I18n.t(`templates.activities.edit.feature_education.tooltip_1`),
              I18n.t(`templates.activities.edit.feature_education.tooltip_2`)
            ]}
            toProgressStrings={[
              I18n.t(`templates.activities.edit.feature_education.next`),
              I18n.t(
                `templates.activities.edit.feature_education.got_it_thanks`
              )
            ]}
            setShowTooltip={setShowTooltip}
          />
        }
        className={styles.popup}
        contentsClassName={styles.contents}
        arrowClassName={styles.arrow}
        position="top"
        isOpenOverride={canViewFeatureEd ? showTooltip : false}
        canClick={false}
        flip={false}
      >
        <div
          {...getRootProps({
            onClick: () => {
              // Fire analytics event for when you click on media uploader
              trackV2({
                category: 'edit_activity',
                page: 'edit_activity',
                action: 'click',
                element: 'add_media',
                properties: {
                  type: 'media',
                  stage: 'default',
                  activity_id: activityId,
                  source: 'main'
                }
              });
            },
            className: cx(
              styles.dropzone,
              files.length > 0 ? '' : styles.centered,
              isDragAccept ? styles.highlight : ''
            )
          })}
        >
          <input
            onClick={(e) => {
              e.preventDefault();
            }}
            {...getInputProps()}
          />
          <DndProvider backend={HTML5Backend}>
            {files.length === 0 && (
              <>
                <ActionsPhotoNormalMedium fill={color.colorExtendedNeutralN1} />
                <p className={styles.header}>
                  {I18n.t(`templates.photos.form.dropzone_header_v2`)}
                </p>
                <p className={styles.subheader}>
                  {I18n.t(`templates.photos.form.dropzone_sub`)}
                </p>
              </>
            )}
            {files.length > 0 && (
              <>
                <div className={styles.mediaContainer}>
                  {files.map((file, index) => {
                    return (
                      <Thumbnail
                        file={file}
                        index={index}
                        remove={remove}
                        loadingStates={loadingStates}
                        uuid={normalizeId(file)}
                        highlightMediaId={highlightMediaId}
                        setHighlightMedia={setHighlightMedia}
                        mediaType={normalizeMediaType(file)}
                        moveThumbnail={moveThumbnail}
                        ref={firstThumbnail}
                      />
                    );
                  })}
                  <AddMediaThumbnail />
                </div>
                {/* Needed to pass value to update activity call */}
                <input
                  type="hidden"
                  name="default_photo_id"
                  value={highlightMediaId}
                />
              </>
            )}
          </DndProvider>
        </div>
      </Popup>
    </div>
  );
}

MediaUploader.defaultProps = {
  defaultPhotoId: null
};

MediaUploader.propTypes = {
  athleteId: PropTypes.number.isRequired,
  media: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultPhotoId: PropTypes.number,
  viewFeatureEdData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activityId: PropTypes.number.isRequired
};

export default MediaUploader;
