import React from 'react';
import PropTypes from 'prop-types';

import { Tab as ReactTab } from 'react-tabs';

import styles from './styles.scss';

const Tab = ({ children, className, selectedClassName, ...props }) => (
  <ReactTab
    className={`${styles.tab} ${className}`}
    selectedClassName={`${styles.selected} ${selectedClassName}`}
    {...props}
  >
    {children}
  </ReactTab>
);

Tab.tabsRole = 'Tab';

Tab.defaultProps = {
  className: '',
  selectedClassName: ''
};

Tab.propTypes = {
  className: PropTypes.string,
  selectedClassName: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Tab;
