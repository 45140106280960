import React, { useState } from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';
import { trackV2 } from 'utils/analytics';
import BaseModal from '../BaseModal';

import styles from './DescriptionEditModal.scss';

const DescriptionEditModal = ({
  isOpen,
  isSaving,
  onDismiss,
  onSubmit,
  description,
  onTextChange,
  title,
  analyticProps
}) => {
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const { entityId, page, feedType, currentPhoto } = analyticProps;

  const handleSubmit = (e) => {
    e.preventDefault();
    trackV2({
      category: 'media',
      page: 'lightbox',
      action: 'click',
      element: 'save_description',
      properties: {
        source: feedType,
        entity_id: entityId,
        entity_type: page,
        element_entity_type: currentPhoto.media_type,
        element_entity_id: currentPhoto.photo_id
      }
    });
    onSubmit();
  };

  const handleTextChange = (e) => {
    const descriptionText = e.target.value;
    setUpdatedDescription(descriptionText);
    onTextChange(descriptionText);
  };

  return (
    <BaseModal
      isOpen={isOpen}
      hasChanges={updatedDescription !== description}
      isSaving={isSaving}
      buttonLabels={{
        savingText: I18n.t('templates.feed.activity.edit_caption.saving'),
        saveText: I18n.t('templates.feed.activity.edit_caption.save')
      }}
      onDismiss={onDismiss}
      onSubmit={handleSubmit}
      title={title}
    >
      <textarea
        className={styles.textArea}
        id="description"
        name="description"
        disabled={isSaving}
        defaultValue={description}
        onChange={handleTextChange}
        onClick={() => {
          trackV2({
            category: 'media',
            page: 'lightbox',
            action: 'interact',
            element: 'description',
            properties: {
              source: feedType,
              entity_id: entityId,
              entity_type: page,
              element_entity_type: currentPhoto.media_type,
              element_entity_id: currentPhoto.photo_id
            }
          });
        }}
      />
    </BaseModal>
  );
};

DescriptionEditModal.propTypes = {
  isOpen: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSubmit: PropTypes.func,
  isSaving: PropTypes.bool,
  description: PropTypes.string,
  onTextChange: PropTypes.func,
  title: PropTypes.string.isRequired,
  analyticProps: PropTypes.shape({
    entityId: PropTypes.string,
    page: PropTypes.string,
    feedType: PropTypes.string,
    currentPhoto: PropTypes.shape({
      media_type: PropTypes.number,
      photo_id: PropTypes.string
    })
  })
};

DescriptionEditModal.defaultProps = {
  isOpen: false,
  onDismiss: () => {},
  onSubmit: () => {},
  isSaving: false,
  onTextChange: () => {},
  description: null,
  analyticProps: undefined
};

export default DescriptionEditModal;
