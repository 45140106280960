import React from 'react';
import PropTypes from 'prop-types';

/*
 * Technique taken with gratitude from:
 * https://medium.com/@jessebeach/dealing-with-focus-and-blur-in-a-composite-widget-in-react-90d3c3b49a9b
 */
class FocusableElement extends React.Component {
  constructor(props) {
    super(props);
    this.timeoutID = null;
  }

  onBlur = () => {
    this.timeoutID = setTimeout(() => {
      if (this.props.isFocused) {
        this.props.onBlur();
      }
    }, 0);
  };

  onFocus = () => {
    clearTimeout(this.timeoutID);
    if (!this.props.isFocused) {
      this.props.onFocus();
    }
  };

  render() {
    const {
      children,
      isFocused,
      onBlur,
      onFocus,
      refFocusable,
      ...props
    } = this.props;
    return (
      <div
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        ref={refFocusable}
        {...props}
      >
        {children}
      </div>
    );
  }
}

FocusableElement.defaultProps = {
  refFocusable: undefined
};

FocusableElement.propTypes = {
  children: PropTypes.node.isRequired,
  isFocused: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  refFocusable: PropTypes.shape({})
};

export default FocusableElement;
