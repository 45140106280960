import React from 'react';
import PropTypes from 'prop-types';

import NoAccess from './NoAccess';
import HasAccess from './HasAccess';

const LoadingState = ({ hasAccess }) =>
  hasAccess ? <HasAccess /> : <NoAccess />;

LoadingState.defaultProps = {
  hasAccess: false
};

LoadingState.propTypes = {
  hasAccess: PropTypes.bool
};

export default LoadingState;
