import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import I18n from 'utils/I18n';
import { MAP_STYLES } from '@strava/ui/Map';
import DropdownSelect from '@strava/ui/DropdownSelect';
import ActivityRoutesNormalXsmall from '@strava/icons/ActivityRoutesNormalXsmall';
import ActionsDownloadNormalXsmall from '@strava/icons/ActionsDownloadNormalXsmall';
import ActionsFullScreenEnterNormalXsmall from '@strava/icons/ActionsFullScreenEnterNormalXsmall';
import ActionsFullScreenExitNormalXsmall from '@strava/icons/ActionsFullScreenExitNormalXsmall';
import ActionsArrowDownNormalXsmall from '@strava/icons/ActionsArrowDownNormalXsmall';
import styles from './MapControls.scss';

const MapControls = ({
  showCreateRoute,
  activityId,
  showGpxDownload,
  showFullScreenToggle,
  mapStyle,
  handleMapStyleChange,
  hasPrivacyZones
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const isSafari =
      /safari/.test(navigator.userAgent.toLowerCase()) &&
      !/chrome/.test(navigator.userAgent.toLowerCase());

    const className = isSafari ? 'fauxscreen' : 'fullscreen';

    if (isFullscreen) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }
  }, [isFullscreen]);

  const handleFullscreenToggleClick = () => {
    const toggleFullscreenEvent = new CustomEvent('toggleFullscreen');
    document.dispatchEvent(toggleFullscreenEvent);

    setIsFullscreen(!isFullscreen);
  };

  const styleOptions = Object.keys(MAP_STYLES).map((style) => ({
    label: I18n.t(`strava.maps.mapbox_gl.custom_control.map_style.${style}`),
    value: style
  }));

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ActionsArrowDownNormalXsmall />
      </components.DropdownIndicator>
    );
  };

  // This is a crazy amount of work to achieve custom height/styling, but this is a well
  // documented/complained about limitation of react-select. Custom styles pulled from
  // https://github.com/JedWatson/react-select/issues/1322#issuecomment-591189551 and
  // modified.
  const customDropdownStyles = {
    control: (base) => ({
      ...base,
      width: 120,
      minHeight: 'initial',
      backgroundColor: '#f7f7fa',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f0f0f5'
      },
      borderColor: '#dfdfe8'
    }),
    valueContainer: (base) => ({
      ...base,
      height: `${30 - 1 - 1}px`,
      padding: '0 8px'
    }),
    singleValue: (base) => ({
      ...base,
      marginBottom: 5,
      color: '#007FB6'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: () => ({
      display: 'flex',
      alignItems: 'center',
      padding: `${(30 - 20 - 1 - 1) / 2}px 8px`,
      color: '#000',
      '& svg': {
        height: 12,
        width: 12
      }
    }),
    menu: (base) => ({
      ...base,
      marginTop: 0,
      zIndex: 3
    }),
    option: (base) => ({
      ...base,
      cursor: 'pointer'
    })
  };

  return (
    <div className={styles.mapControlContainer}>
      <div className={styles.mapControls}>
        {showCreateRoute && (
          <a
            className={styles.button}
            id="create-route"
            href={`/activities/${activityId}/route`}
          >
            <ActivityRoutesNormalXsmall />
            <span>
              {I18n.t('strava.maps.mapbox_gl.custom_control.create_route')}
            </span>
          </a>
        )}

        {showGpxDownload && (
          <a
            className={styles.button}
            id="gpx-download"
            href={`/activities/${activityId}/export_gpx`}
          >
            <ActionsDownloadNormalXsmall />
            <span>
              {I18n.t('strava.maps.mapbox_gl.custom_control.export_gpx')}
            </span>
          </a>
        )}

        <DropdownSelect
          value={styleOptions.find((option) => option.value === mapStyle)}
          onChange={handleMapStyleChange}
          options={styleOptions}
          isSearchable={false}
          blurInputOnSelect={true}
          components={{
            DropdownIndicator
          }}
          styles={customDropdownStyles}
        />

        {showFullScreenToggle && (
          <button
            className={`${styles.button} ${styles.fullScreenToggle}`}
            onClick={handleFullscreenToggleClick}
            label={I18n.t(
              isFullscreen
                ? 'strava.maps.mapbox_gl.custom_control.exit_fullscreen_mode'
                : 'strava.maps.mapbox_gl.custom_control.enter_fullscreen_mode'
            )}
          >
            {isFullscreen ? (
              <ActionsFullScreenExitNormalXsmall />
            ) : (
              <ActionsFullScreenEnterNormalXsmall />
            )}
          </button>
        )}
      </div>

      {hasPrivacyZones && (
        <div className={styles.privacyLegend}>
          <div className={styles.legendItem}>
            <div className={`${styles.line} ${styles.visible}`} />
            <div>
              {I18n.t(
                'strava.maps.mapbox_gl.custom_control.route_visible_label'
              )}
            </div>
          </div>
          <div className={styles.legendItem}>
            <div className={`${styles.line} ${styles.hidden}`} />
            <div>
              {I18n.t(
                'strava.maps.mapbox_gl.custom_control.route_hidden_label'
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MapControls.propTypes = {
  showCreateRoute: PropTypes.bool,
  activityId: PropTypes.number.isRequired,
  showGpxDownload: PropTypes.bool,
  showFullScreenToggle: PropTypes.bool,
  mapStyle: PropTypes.string.isRequired,
  handleMapStyleChange: PropTypes.func.isRequired,
  hasPrivacyZones: PropTypes.bool.isRequired
};

MapControls.defaultProps = {
  showCreateRoute: true,
  showGpxDownload: true,
  showFullScreenToggle: false
};

export default MapControls;
