import React from 'react';
import I18n from 'utils/I18n';

import styles from './styles.scss';

const I18nKey = 'strava.progress_goals';

function Footer() {
  return (
    <div className="card">
      <div className="card-footer">
        <a
          className={`${styles.btnCardLink} media media-middle`}
          href="/athlete/goals"
        >
          <div className="media-body">
            {I18n.t(`${I18nKey}.manage_your_goals`)}
          </div>
          <div className="media-right">
            <span className={`${styles.appIconWrapper}`}>
              <span className="app-icon icon-caret-right icon-dark icon-lg" />
            </span>
          </div>
        </a>
      </div>
    </div>
  );
}

Footer.defaultProps = {};

Footer.propTypes = {};

export default Footer;
