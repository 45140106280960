import React from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';

import Cldr from 'utils/Cldr';
import I18n from 'utils/I18n';
import { trackV2 } from 'utils/analytics';

import styles from './styles.scss';

export const I18nPrefix = 'components.comments';

const Comment = ({
  id,
  avatar,
  authorId,
  authorName,
  timestamp,
  deletable,
  quarantinable,
  reportable,
  bodyText,
  className,
  deleteComment,
  parentId
}) => {
  const logReportComment = (e) => {
    e.preventDefault();

    trackV2({
      category: 'challenges',
      page: 'comment',
      action: 'click',
      element: 'report',
      properties: {
        comment_id: id,
        post_id: parentId
      }
    });

    window.location = `/comments/${id}/feedback`;
  };

  return (
    <div className={`${className} ${styles.container}`}>
      <div className={styles.avatarWrapper}>{avatar}</div>
      <div className={styles.center}>
        <div className={styles.header}>
          <div className="text-footnote mt-0 mb-0">
            <a href={`/athletes/${authorId}`} className={styles.authorName}>
              {authorName}
            </a>
          </div>
        </div>
        <div className={styles.body}>
          <div className={`text-caption3 mb-0 ${styles.bodyText}`}>
            {bodyText}
          </div>
        </div>
      </div>
      <div className={styles.hoverable}>
        {reportable && (
          <div className={`text-caption2 ${styles.report}`}>
            <a
              className={`text-caption2 ${styles.reportLink}`}
              href={`/comments/${id}/feedback`}
              onClick={logReportComment}
            >
              {I18n.t(`${I18nPrefix}.report`)}
            </a>
          </div>
        )}
        {quarantinable && (
          <div className={`text-caption2 ${styles.quarantine}`}>
            <a
              className={`text-caption2 ${styles.quarantineLink}`}
              href={`/admin/quarantine/comment_form?comment_id=${id}`}
            >
              {I18n.t(`${I18nPrefix}.soft_delete`)}
            </a>
          </div>
        )}
        {deletable && (
          <div className={`text-caption2 ${styles.delete}`}>
            <Button
              type="button"
              variant="text"
              className={`text-caption2 ${styles.deleteButton}`}
              onClick={() => deleteComment(id, parentId)}
            >
              {I18n.t(`${I18nPrefix}.delete`)}
            </Button>
          </div>
        )}
        <div className={`text-caption2 ${styles.timestamp}`}>
          {Cldr.formatTimespan(timestamp)}
        </div>
      </div>
    </div>
  );
};

Comment.defaultProps = {
  className: '',
  quarantinable: false
};

Comment.propTypes = {
  id: PropTypes.number.isRequired,
  avatar: PropTypes.element.isRequired,
  authorId: PropTypes.number.isRequired,
  authorName: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  deletable: PropTypes.bool.isRequired,
  quarantinable: PropTypes.bool,
  reportable: PropTypes.bool.isRequired,
  bodyText: PropTypes.string.isRequired,
  className: PropTypes.string,
  deleteComment: PropTypes.func.isRequired,
  parentId: PropTypes.number.isRequired
};

export default Comment;
