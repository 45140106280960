import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const Handle = ({
  entityId,
  domain: [min, max],
  handle,
  disabled,
  getHandleProps
}) => {
  const classNames = [styles.slider];

  if (disabled) {
    classNames.push(styles.disabled);
  }
  if (handle.percent === 0) {
    classNames.push(styles.start);
  } else if (handle.percent === 100) {
    classNames.push(styles.end);
  }

  return (
    <button
      id={`perceived-exertion-slider-${entityId}`}
      role="slider"
      type="button"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={handle.value}
      className={classNames.join(' ')}
      style={{
        left: `${handle.percent}%`
      }}
      {...getHandleProps(handle.id)}
    />
  );
};

Handle.defaultProps = {
  disabled: false
};

Handle.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  domain: PropTypes.arrayOf(Number).isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default Handle;
