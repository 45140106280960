import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs';
import '@reach/tabs/styles.css';

import ProgressGoalsRelativeEffortTab from 'components/ProgressGoalsRelativeEffortTab';
import ProgressGoalsSidebar from 'components/ProgressGoals/Sidebar';

import RelativeEffortTabIcon from '@strava/icons/NavigationTrainingNormalXsmall';
import ProgressGoalsTabIcon from '@strava/icons/ActionsPendingNormalXsmall';
import styles from './styles.scss';

const tabLocalStorageKey = 'strava.progressGoals';
const defaultTabName = 'progress-goals';
const tabIndexMapping = ['relative-effort-goals', 'progress-goals'];

const createTabStore = () => {
  const tabIndex = () => {
    if (localStorage) {
      const tabStorageData = JSON.parse(
        localStorage.getItem(tabLocalStorageKey)
      );
      if (tabStorageData) {
        const idx = tabIndexMapping.indexOf(tabStorageData.selectedTabName);
        if (idx >= 0) {
          return idx;
        }
      }
    }
    return tabIndexMapping.indexOf(defaultTabName);
  };

  const setTabIndex = (index) => {
    if (localStorage) {
      if (index < tabIndexMapping.length) {
        localStorage.setItem(
          tabLocalStorageKey,
          JSON.stringify({ selectedTabName: tabIndexMapping[index] })
        );
      }
    }
  };

  return {
    tabIndex,
    setTabIndex
  };
};

function ProgressGoalsSidebarTabContainer({
  athleteId,
  hasAccess,
  isTrialEligible,
  unitPreference,
  relativeEffortSubscriptionUrl
}) {
  const tabStateStore = createTabStore();
  const [tabIndex, setTabIndex] = useState(tabStateStore.tabIndex());

  const onTabChange = (idx) => {
    setTabIndex(idx);
    tabStateStore.setTabIndex(idx);
  };

  return (
    <Tabs
      className={styles.progressGoalsTabs}
      index={tabIndex}
      onChange={onTabChange}
    >
      <TabList>
        <Tab>
          <RelativeEffortTabIcon className={styles.appIcon} />
        </Tab>
        <Tab>
          <ProgressGoalsTabIcon className={styles.appIcon} />
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <ProgressGoalsRelativeEffortTab
            athleteId={athleteId}
            hasAccess={hasAccess}
            subscriptionUrl={relativeEffortSubscriptionUrl}
            isTrialEligible={isTrialEligible}
          />
        </TabPanel>
        <TabPanel>
          <ProgressGoalsSidebar
            athleteId={athleteId}
            hasAccess={hasAccess}
            unitPreference={unitPreference}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

ProgressGoalsSidebarTabContainer.defaultProps = {};

ProgressGoalsSidebarTabContainer.propTypes = {
  athleteId: PropTypes.number.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  isTrialEligible: PropTypes.bool.isRequired,
  unitPreference: PropTypes.string.isRequired,
  relativeEffortSubscriptionUrl: PropTypes.string.isRequired
};

export default ProgressGoalsSidebarTabContainer;
