import React from 'react';
import PropTypes from 'prop-types';
import Button from '@strava/ui/Button';

import I18n from 'utils/I18n';
import styles from './styles.scss';

class TaggingLinkCopy extends React.Component {
  state = {
    showCopiedText: false,
    branchLink: this.props.branchLinkData.desktopUrl
  };

  componentDidMount() {
    const {
      branchLinkData,
      activityId,
      activityType,
      athleteId,
      athleteName
    } = this.props;
    const { signature, desktopUrl, inviterAvatarUrl } = branchLinkData;

    const data = {
      channel: 'web',
      feature: 'activity-tagging',
      data: {
        '$desktop_url': desktopUrl, // eslint-disable-line
        strava_deeplink_url: `strava://activities/${activityId}?activity_tag=true&sig=${signature}`,
        inviter_athlete_id: athleteId.toString(),
        inviter_first_name: athleteName,
        inviter_avatar_url: inviterAvatarUrl,
        inviter_tagged_activity_id: activityId.toString(),
        inviter_activity_type_key: activityType,
        inviter_activity_signature: signature,
        redirect_after_signup: 'true'
      }
    };
    try {
      window.Strava.BranchIO.createLink(data).done((link) => {
        this.setState({ branchLink: link });
      });
    } catch (err) {
      console.warn(err); // eslint-disable-line
    }
  }

  componentWillUnmount() {
    if (this.hideCopiedTextTimer) {
      clearTimeout(this.hideCopiedTextTimer);
      this.hideCopiedTextTimer = null;
    }
  }

  onInputClick = () => {
    this.input.select();
  };

  copyToClipboard = () => {
    this.input.select();
    // This is the actual code that copies selected text to clipboard
    const successful = document.execCommand('copy');
    if (successful) {
      this.setState(() => ({ showCopiedText: true }));
      this.hideCopiedText();
    }
  };

  hideCopiedText = () => {
    this.hideCopiedTextTimer = setTimeout(() => {
      this.setState(() => ({ showCopiedText: false }));
    }, 5000);
  };

  render() {
    return (
      <div className={styles.linkCopy}>
        <div className={styles.copyBase}>
          <input
            ref={(el) => {
              this.input = el;
            }}
            className={styles.copyText}
            type="text"
            value={this.state.branchLink}
            onClick={this.onInputClick}
            readOnly={true}
          />
          <Button
            type="button"
            variant="primary"
            className={styles.copyButton}
            onClick={this.copyToClipboard}
          >
            {I18n.t('strava.activities.tagging.invite_module.copy_cta')}
          </Button>
        </div>

        <div
          className={`${styles.copiedToClipboard} ${
            this.state.showCopiedText ? styles.copied : ''
          }`}
        >
          <div className={`text-center ${styles.wrapper}`}>
            <span className="app-icon-wrapper">
              <span className="app-icon icon-strava icon-sm icon-save" />
            </span>
            {I18n.t('strava.activities.tagging.invite_module.copied')}
          </div>
        </div>
      </div>
    );
  }
}

TaggingLinkCopy.propTypes = {
  branchLinkData: PropTypes.shape({
    signature: PropTypes.string.isRequired,
    desktopUrl: PropTypes.string.isRequired,
    inviterAvatarUrl: PropTypes.string.isRequired
  }).isRequired,
  activityId: PropTypes.number.isRequired,
  activityType: PropTypes.string.isRequired,
  athleteName: PropTypes.string.isRequired,
  athleteId: PropTypes.number.isRequired
};

export default TaggingLinkCopy;
