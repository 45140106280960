import React from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';
import ElevationChart from '@strava/ui/ElevationChart';
import RangeSlider from 'components/RangeSlider';
import RangeSelectMap from 'components/RangeSelectMap';

// Stylesheets
import styles from './styles.scss';

import { fetchStreams, checkSegment, getSegmentStreamById } from './api';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import { logError } from '../../../utils/sentry';

const I18nPrefix = 'publishes.wizard';

class CreateSegment extends React.Component {
  state = {
    currentStep: 1,
    activityLatlng: [],
    segmentLatlng: [],
    similarSegments: [],
    selectedSimilarSegmentsId: null,
    scopeTo: null,
    altitude: [],
    distance: [],
    isLoading: false,
    hasMinDistanceError: false
  };

  async componentDidMount() {
    const { entityId, entitySource, startIndex, endIndex } = this.props;

    this.setState({ isLoading: true });

    try {
      const { altitude, distance, latlng } = await fetchStreams({
        id: entityId,
        source: entitySource
      });

      const scopeTo = [
        startIndex === null ? 0 : startIndex,
        endIndex === null ? parseInt(latlng.length / 2, 10) : endIndex
      ];

      this.setState({
        isLoading: false,
        scopeTo,
        activityLatlng: latlng,
        segmentLatlng: latlng.slice(scopeTo[0], scopeTo[1] + 1),
        altitude,
        distance
      });
    } catch (error) {
      // TODO: show some error to the user
      logError(error);
      this.setState({ isLoading: true });
    }
  }

  checkSimilarSegments = async () => {
    const { entityId, entitySource } = this.props;
    const { scopeTo } = this.state;

    this.setState({ isLoading: true });

    try {
      const response = await checkSegment({
        origin: entitySource,
        id: entityId,
        startIndex: scopeTo[0],
        endIndex: scopeTo[1]
      });

      //
      // Minimal distance error, stay on step 1 and show error to the user
      //
      if (response.min_distance_error) {
        this.setState({
          isLoading: false,
          hasMinDistanceError: response.min_distance_error
        });
        return;
      }

      //
      // There are no similar segments so we can skip step 2 and jump to step 3
      //
      if (response.overlaps.length === 0) {
        this.setState({
          isLoading: false,
          currentStep: 3
        });
        return;
      }

      const selectedSimilarSegmentsId = response.overlaps[0].id;

      this.setState(
        {
          isLoading: false,
          currentStep: 2,
          similarSegments: response.overlaps.map((segment) => ({
            ...segment,
            latLng: []
          })),
          selectedSimilarSegmentsId
        },
        () => {
          this.fetchSimilarSegmentById(selectedSimilarSegmentsId);
        }
      );
    } catch (error) {
      // TODO: show some error to the user
      logError(error);
      this.setState({ isLoading: false });
    }
  };

  fetchSimilarSegmentById = async (id) => {
    const { similarSegments } = this.state;

    this.setState({ isLoading: true });

    try {
      const response = await getSegmentStreamById(id);

      this.setState({
        isLoading: false,
        selectedSimilarSegmentsId: id,
        similarSegments: similarSegments.map((segment) => {
          if (segment.id === id) {
            return {
              ...segment,
              latLng: response
            };
          }

          return segment;
        })
      });
    } catch (error) {
      // TODO: show some error to the user
      logError(error);
      this.setState({ isLoading: false });
    }
  };

  handleCancelBtnClick = () => {
    const { paths } = this.props;

    window.location = paths.cancel;
  };

  handleRangeSliderChange = (values) => {
    const { activityLatlng } = this.state;

    this.setState({
      scopeTo: values,
      segmentLatlng: activityLatlng.slice(values[0], values[1] + 1)
    });
  };

  handleSimilarItemClick = (id) => {
    const selectedSegment = this.getSelectedSegmentById(id);

    if (id === null) {
      this.setState({ selectedSimilarSegmentsId: null });
    } else if (selectedSegment.latLng.length > 0) {
      this.setState({ selectedSimilarSegmentsId: id });
    } else {
      this.fetchSimilarSegmentById(id);
    }
  };

  getSelectedSegmentById = (id) => {
    const { similarSegments } = this.state;

    return similarSegments.filter((segment) => segment.id === id)[0];
  };

  renderStep = () => {
    const { paths, entityId, entitySource, privateOnly, isEdit } = this.props;

    const {
      isLoading,
      currentStep,
      scopeTo,
      hasMinDistanceError,
      activityLatlng,
      similarSegments,
      selectedSimilarSegmentsId
    } = this.state;

    if (currentStep === 1) {
      return (
        <Step1
          isLoading={activityLatlng.length > 0 && isLoading}
          hasMinError={hasMinDistanceError}
          onNextBtnClick={this.checkSimilarSegments}
          onCancelBtnClick={this.handleCancelBtnClick}
        />
      );
    }

    if (currentStep === 2) {
      return (
        <Step2
          isLoading={isLoading}
          isEdit={isEdit}
          selectedId={selectedSimilarSegmentsId}
          segments={similarSegments}
          onSegmentClick={this.handleSimilarItemClick}
          onNextBtnClick={() => {
            // When there is not selected ID, we are createing a new segment so go to step 3
            if (selectedSimilarSegmentsId === null) {
              this.setState({ currentStep: 3 });
            } else {
              window.location = `/segments/${selectedSimilarSegmentsId}`;
            }
          }}
          onCancelBtnClick={this.handleCancelBtnClick}
        />
      );
    }

    return (
      <Step3
        entityId={entityId}
        entitySource={entitySource}
        privateOnly={privateOnly}
        isEdit={isEdit}
        onCancelBtnClick={this.handleCancelBtnClick}
        startIndex={scopeTo[0]}
        endIndex={scopeTo[1]}
        paths={paths}
      />
    );
  };

  renderMap = () => {
    const { privacyZones } = this.props;
    const {
      currentStep,
      activityLatlng,
      segmentLatlng,
      selectedSimilarSegmentsId
    } = this.state;

    if (currentStep === 1) {
      return (
        <RangeSelectMap
          currentStep={currentStep}
          primaryLatLng={activityLatlng}
          secondaryLatlng={segmentLatlng}
          fitBounds={segmentLatlng}
          privacyZones={privacyZones}
          checkLatlngChanges={true}
        />
      );
    }

    if (currentStep === 2) {
      const selectedSegment = this.getSelectedSegmentById(
        selectedSimilarSegmentsId
      );

      return (
        <RangeSelectMap
          currentStep={currentStep}
          primaryLatLng={segmentLatlng}
          secondaryLatlng={
            selectedSimilarSegmentsId === null ? [] : selectedSegment.latLng
          }
          fitBounds={
            selectedSimilarSegmentsId === null
              ? segmentLatlng
              : selectedSegment.latLng
          }
        />
      );
    }

    return (
      <RangeSelectMap
        currentStep={currentStep}
        primaryLatLng={segmentLatlng}
        fitBounds={segmentLatlng}
      />
    );
  };

  render() {
    const { unitSystem, isEdit } = this.props;
    const {
      currentStep,
      scopeTo,
      activityLatlng,
      altitude,
      distance
    } = this.state;

    return (
      <div className={styles.page}>
        <div className={`${styles.panel} ${styles.leftPanel}`}>
          <h1>
            {I18n.t(`${I18nPrefix}.heading.${isEdit ? 'edit' : 'create'}`)}
          </h1>
          <div className={styles.section}>
            <p>{I18n.t(`${I18nPrefix}.create_segment_body`)}</p>
            <p className="mb-0">
              {I18n.t_html(`${I18nPrefix}.create_segment_body_2_html`, {
                learn_more_link:
                  'https://support.strava.com/hc/en-us/articles/216918827'
              })}
            </p>
          </div>

          <div className={styles.section}>{this.renderStep()}</div>

          <div className={styles.section}>
            <h3>{I18n.t(`${I18nPrefix}.for_best_results`)}</h3>
            <ul className={styles.bestResults}>
              <li>{I18n.t(`${I18nPrefix}.segment_creation_tips_4_v2`)}</li>
              <li>{I18n.t(`${I18nPrefix}.segment_creation_tips_1_v2`)}</li>
              <li>{I18n.t(`${I18nPrefix}.segment_creation_tips_2`)}</li>
              <li>{I18n.t(`${I18nPrefix}.segment_creation_tips_3`)}</li>
            </ul>
          </div>
        </div>
        <div className={`${styles.panel} ${styles.rightPanel}`}>
          {currentStep === 1 && activityLatlng.length > 0 && (
            <div className="mb-md">
              <RangeSlider
                domain={[0, activityLatlng.length - 1]}
                scopeTo={scopeTo}
                onUpdate={this.handleRangeSliderChange}
              />
            </div>
          )}
          <div>{activityLatlng.length > 0 && this.renderMap()}</div>
          <div>
            {altitude.length > 0 && (
              <ElevationChart
                height={220}
                units={unitSystem}
                elevation={altitude}
                distance={distance}
                scopeTo={scopeTo}
                scopeColor="#fc5200"
                formatters={{
                  ElevationFormatter(units) {
                    return I18n.elevationFormatter(units);
                  },
                  DistanceFormatter(units) {
                    return I18n.distanceFormatter(units);
                  },
                  PercentFormatter(units) {
                    return I18n.percentFormatter(units);
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
//

CreateSegment.defaultProps = {
  privacyZones: [],
  startIndex: null,
  endIndex: null,
  isEdit: false
};

CreateSegment.propTypes = {
  entityId: PropTypes.number.isRequired,
  entitySource: PropTypes.string.isRequired,
  privateOnly: PropTypes.bool.isRequired,
  unitSystem: PropTypes.string.isRequired,
  paths: PropTypes.shape({
    create: PropTypes.string.isRequired,
    save: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired
  }).isRequired,
  privacyZones: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired
      }).isRequired,
      radius: PropTypes.number.isRequired
    })
  ),
  startIndex: PropTypes.number,
  endIndex: PropTypes.number,
  isEdit: PropTypes.bool
};

export default CreateSegment;
