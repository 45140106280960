import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@strava/ui/Button';

import I18n from 'utils/I18n';

import styles from './styles.scss';
import { joinChallenge, leaveChallenge, showAgeModal } from '../../../actions';
import GatingHelper from '../../../../shared/helpers/gating_helper';

export const I18nPrefix = 'strava.challenges.challenge_detail';

export class ChallengeJoinButton extends React.Component {
  state = {
    keepHovered: false
  };

  onJoinChallenge = () => {
    return GatingHelper.shouldShowAgeGateModal(
      this.props.loggedIn,
      this.props.joined,
      this.props.currentAthlete && this.props.currentAthlete.dateOfBirth,
      this.props.gatingConditions
    )
      ? this.props.showAgeModal(
          this.props.gatingConditions,
          () => this.props.onJoinChallenge(this.props.challengeId),
          new Date('1970-1-1')
        )
      : this.props.onJoinChallenge(this.props.challengeId);
  };

  onLeaveChallenge = () => this.props.onLeaveChallenge(this.props.challengeId);

  onHoverToggle = () =>
    this.setState((prevState) => ({
      keepHovered: !prevState.keepHovered
    }));

  joinButton = (externalJoinUrl) => (
    <>
      {externalJoinUrl ? (
        <a
          className={`btn btn-primary btn-sm btn-block text-footnote ${styles.button}`}
          href={externalJoinUrl}
        >
          {I18n.t(`${I18nPrefix}.register`)}
        </a>
      ) : (
        <Button
          disabled={this.props.challengeJoinRequested}
          variant="primary"
          className={`btn-sm btn-block text-footnote ${styles.button}`}
          onClick={this.onJoinChallenge}
          data-cy="challenge_join_button"
        >
          {I18n.t(`${I18nPrefix}.join_challenge`)}
        </Button>
      )}
    </>
  );

  leaveButton = () => (
    <>
      {this.state.keepHovered || this.props.challengeLeaveRequested ? (
        <Button
          disabled={this.props.challengeLeaveRequested}
          variant="primary"
          className={` btn-sm btn-block text-footnote ${styles.button}`}
          onMouseLeave={this.onHoverToggle}
          onClick={this.onLeaveChallenge}
          data-cy="leave_challenge_button"
        >
          {I18n.t(`${I18nPrefix}.leave_challenge`)}
        </Button>
      ) : (
        <Button
          variant="primaryOutline"
          className={`btn-sm btn-block text-footnote ${styles.button}`}
          onMouseEnter={this.onHoverToggle}
          data-cy="challenge_joined_button"
        >
          {I18n.t(`${I18nPrefix}.challenge_joined`)}
        </Button>
      )}
    </>
  );

  render() {
    const { joined, externalJoinUrl } = this.props;

    return (
      <div className={styles.container}>
        {joined ? this.leaveButton() : this.joinButton(externalJoinUrl)}
      </div>
    );
  }
}

ChallengeJoinButton.defaultProps = {
  externalJoinUrl: null,
  challengeJoinRequested: false,
  challengeLeaveRequested: false,
  gatingConditions: null,
  currentAthlete: null,
  loggedIn: false
};

ChallengeJoinButton.propTypes = {
  onJoinChallenge: PropTypes.func.isRequired,
  onLeaveChallenge: PropTypes.func.isRequired,
  showAgeModal: PropTypes.func.isRequired,
  challengeJoinRequested: PropTypes.bool,
  challengeLeaveRequested: PropTypes.bool,
  challengeId: PropTypes.number.isRequired,
  joined: PropTypes.bool.isRequired,
  externalJoinUrl: PropTypes.string,
  gatingConditions: PropTypes.shape({
    minimumAge: PropTypes.number
  }),
  currentAthlete: PropTypes.shape({
    dateOfBirth: PropTypes.string
  }),
  loggedIn: PropTypes.bool
};

export default connect(
  (state) => ({
    challengeJoinRequested: state.challengeJoinRequested,
    challengeLeaveRequested: state.challengeLeaveRequested,
    challengeId: state.challengeId,
    joined: state.joined,
    externalJoinUrl: state.externalJoinUrl
  }),
  {
    onJoinChallenge: joinChallenge,
    onLeaveChallenge: leaveChallenge,
    showAgeModal
  }
)(ChallengeJoinButton);
