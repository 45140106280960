import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class Portal extends React.Component {
  portalNode;

  state = {
    isMounted: false
  };

  componentDidMount() {
    const { id } = this.props;

    this.portalNode = document.createElement('div');
    this.portalNode.setAttribute('data-portal-id', `portal-${id}`);
    document.body.appendChild(this.portalNode);

    this.setState({ isMounted: true });
  }

  componentWillUnmount() {
    document.body.removeChild(this.portalNode);
  }

  render() {
    const { children } = this.props;
    const { isMounted } = this.state;

    if (!isMounted) {
      return null;
    }

    return ReactDOM.createPortal(children, this.portalNode);
  }
}

Portal.defaultProps = {
  children: null
};

Portal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default Portal;
