import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const customStyles = {
  overlay: {
    zIndex: '100'
  },
  afterOpen: '',
  beforeClose: ''
};

const Modal = (props) => (
  <ReactModal
    closeTimeoutMS={200}
    isOpen={props.isOpen}
    onRequestClose={props.onRequestClose}
    shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
    ariaHideApp={props.ariaHideApp}
    bodyOpenClassName={`modal-open ${styles.modalOpen}`}
    overlayClassName={{
      base: `${styles.base} ${props.className}`,
      afterOpen: `${styles.fadeIn}`,
      beforeClose: `${styles.fadeOut}`
    }}
    style={customStyles}
    className={`${styles.dialog} ${props.dialogClassName}`}
    onAfterOpen={props.onAfterOpen}
  >
    <div className={styles.content}>{props.children}</div>
  </ReactModal>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  ariaHideApp: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  className: PropTypes.string,
  dialogClassName: PropTypes.string,
  onAfterOpen: PropTypes.func
};

Modal.defaultProps = {
  ariaHideApp: false,
  shouldCloseOnOverlayClick: true,
  className: '',
  dialogClassName: '',
  onAfterOpen: null
};

export default Modal;
