import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import I18n from 'utils/I18n';
import { csrfToken } from 'utils/networking-client';

import StepActions from '../../components/StepActions';

import styles from './styles.scss';

const I18nPrefix = 'publishes.wizard';

function Step3({
  entityId,
  entitySource,
  privateOnly,
  isEdit,
  paths,
  startIndex,
  endIndex,
  onCancelBtnClick
}) {
  const [isValid, setIsValid] = useState(true);
  const [segmentName, setSegmentName] = useState(null);
  const [isPrivate, setIsPrivate] = useState(true);

  useEffect(() => {
    if (segmentName !== null) {
      setIsValid(segmentName.trim().length !== 0);
    }
  }, [segmentName]);

  function create() {
    return (
      <>
        <h2>{I18n.t(`${I18nPrefix}.name_segment`)}</h2>
        <p>{I18n.t(`${I18nPrefix}.name_segment_more`)}</p>
        <div className={`${styles.name} ${isValid ? '' : styles.error}`}>
          <input
            type="text"
            id="segment_name"
            name="segment_name"
            className={styles.nameInput}
            value={segmentName || ''}
            onChange={(e) => setSegmentName(e.target.value)}
          />
          {!isValid && (
            <label htmlFor="segment_name" className={styles.nameError}>
              This field is required.
            </label>
          )}
        </div>
        <div className={styles.private}>
          <label className="mt-sm mb-sm">
            <input
              type="checkbox"
              name="private"
              checked={isPrivate}
              disabled={privateOnly}
              onChange={() => setIsPrivate(!isPrivate)}
            />
            {I18n.t(`${I18nPrefix}.make_private`)}
          </label>
        </div>
        <p>{I18n.t(`${I18nPrefix}.make_private_more`)}</p>
        <StepActions
          actionType="submit"
          actionVariant="create"
          onCancel={onCancelBtnClick}
          disabled={segmentName === null || !isValid}
        />
      </>
    );
  }

  function edit() {
    return (
      <>
        <h2>{I18n.t(`${I18nPrefix}.edits_complete`)}</h2>
        <p>{I18n.t(`${I18nPrefix}.edits_complete_more`)}</p>
        <StepActions
          actionType="submit"
          actionVariant="save"
          onCancel={onCancelBtnClick}
        />
      </>
    );
  }

  return (
    <form
      action={isEdit ? paths.save : paths.create}
      method="post"
      acceptCharset="UTF-8"
    >
      <input type="hidden" name="authenticity_token" value={csrfToken()} />
      <input type="hidden" name="id" value={entityId} />
      <input type="hidden" name="source" value={entitySource} />
      <input type="hidden" name="origin" value={entitySource} />
      {isEdit && <input type="hidden" name="edit" value={isEdit} />}
      <input type="hidden" name="start_index" value={startIndex} />
      <input type="hidden" name="end_index" value={endIndex} />
      {isEdit ? edit() : create()}
    </form>
  );
}

Step3.defaultProps = {
  isEdit: false
};

Step3.propTypes = {
  entityId: PropTypes.number.isRequired,
  entitySource: PropTypes.string.isRequired,
  privateOnly: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  paths: PropTypes.shape({
    create: PropTypes.string.isRequired,
    save: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired
  }).isRequired,
  startIndex: PropTypes.number.isRequired,
  endIndex: PropTypes.number.isRequired,
  onCancelBtnClick: PropTypes.func.isRequired
};

export default Step3;
