import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';
import createNetworkingClient from 'utils/networking-client';
import Avatar from '@strava/ui/Avatar';
import Spinner from '@strava/ui/Spinner';
import { Markup } from 'interweave';

import ModalWithTabs, {
  ModalTabs,
  ModalTabList,
  ModalTab,
  ModalTabPanels,
  ModalTabPanel
} from '@strava/ui/ModalWithTabs';
import Button, { LinkButton } from '@strava/ui/Button';
import { isEmpty } from 'lodash-es';
import AchievementsMedalPrSmall from '@strava/icons/AchievementsMedalPrSmall';
import AchievementsMedal02Small from '@strava/icons/AchievementsMedal02Small';
import AchievementsMedal03Small from '@strava/icons/AchievementsMedal03Small';
import AchievementsBadgeHighlightedSmall from '@strava/icons/AchievementsBadgeHighlightedSmall';
import AchievementsKomHighlightedSmall from '@strava/icons/AchievementsKomHighlightedSmall';
import AchievementsTrophy02Small from '@strava/icons/AchievementsTrophy02Small';
import AchievementsTrophy03Small from '@strava/icons/AchievementsTrophy03Small';
import AchievementsTrophy04Small from '@strava/icons/AchievementsTrophy04Small';
import AchievementsTrophy05Small from '@strava/icons/AchievementsTrophy05Small';
import AchievementsTrophy06Small from '@strava/icons/AchievementsTrophy06Small';
import AchievementsTrophy07Small from '@strava/icons/AchievementsTrophy07Small';
import AchievementsTrophy08Small from '@strava/icons/AchievementsTrophy08Small';
import AchievementsTrophy09Small from '@strava/icons/AchievementsTrophy09Small';
import AchievementsTrophy10Small from '@strava/icons/AchievementsTrophy10Small';
import SvgActionsArrowLeftNormalSmall from '@strava/icons/ActionsArrowLeftNormalSmall';
import Cldr from 'utils/Cldr';
import { FEED_ENTRY_TYPES } from '../../../Feed/constants';
import AvatarAndMeta from '../AvatarAndMeta';
import CommentReactionList from '../CommentReactionList';
import {
  viewingAthleteProps,
  entityOwnerProps,
  segAndBestEffortAchievementsProps
} from '../../../Feed/props/feed-prop-types';
import styles from './styles.scss';
import MentionableCommentForm from '../../MentionableCommentForm';
import { buildTimeAgoTimestamp } from '../../utils/buildTimeAgoTimeStamp';
import { useKudosAndCommentsContext } from '../KudosAndCommentsContext';
import {
  CLOSE_MODAL,
  ADD_KUDO,
  DELETE_COMMENT_START,
  CLOSE_COMMENT_REACTION_LIST
} from '../kudosAndComments.actions';
import { updateKudosEndpoint } from '../../utils/updateKudosEndpoint';
import { logError } from '../../../../utils/sentry';

const prefix = 'templates.kudos_and_comments_modal';

const KudosCommentsAndAchievementsModal = ({
  title,
  segAndBestEffortAchievements,
  viewingAthlete,
  entity,
  entityId,
  hasOtherAthletes,
  entityOwner,
  kudosCount,
  commentsEnabled,
  clubMentionsEnabled,
  commentReactionAnalytics
}) => {
  const {
    kudosAndCommentsEntityState,
    dispatch
  } = useKudosAndCommentsContext();

  const hasAchievements = !isEmpty(segAndBestEffortAchievements);
  const correctedTabIndex = () => {
    let { selectedTabIndex: tabIndexFromReducer } = kudosAndCommentsEntityState;
    if (!hasAchievements && tabIndexFromReducer === 3) {
      // adjust tab index if the athlete entry has no achievements tab
      tabIndexFromReducer -= 1;
    }
    if (!commentsEnabled) {
      // adjust tab index if comments are disabled for entity
      tabIndexFromReducer -= 1;
    }
    return tabIndexFromReducer;
  };

  const [index, setIndex] = useState(correctedTabIndex());
  const [groupedActivityData, setGroupedActivityData] = useState({
    athletes: [],
    hasOtherAthletes
  });
  const [hover, setHover] = useState(false);
  const [kudoData, setKudosData] = useState({
    isOwner: false,
    kudosable: false,
    athletes: []
  });
  const [commentReactionListData, setCommentReactionListData] = useState({
    reactionCount: 0,
    athletes: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const [followRequestApprovalStatusMap, setAthleteFollowRequestMap] = useState(
    {}
  );
  const [isViewingCommentReactionList, setIsViewingReactionList] = useState(
    kudosAndCommentsEntityState.isViewingCommentReactionsList
  );

  const comments = kudosAndCommentsEntityState.comments[entityId] || [];
  const entityEndpoint = updateKudosEndpoint({ entity });

  const fetchKudoList = useCallback(() => {
    setIsLoading(true);
    createNetworkingClient()
      .get(`/feed/${entityEndpoint}/${entityId}/kudos`)
      .then(({ data }) => {
        setKudosData(data);
        setIsLoading(false);
      });
  }, [entityId, entityEndpoint]);

  const fetchCommentReactionList = useCallback(() => {
    const selectedCommentId = kudosAndCommentsEntityState.selectedComment;
    setIsLoading(true);
    createNetworkingClient()
      .get(`/comments/${selectedCommentId}/reactions`)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.athletes) {
          setCommentReactionListData({
            reactionCount: data.reaction_count,
            athletes: data.athletes
          });
        }
        return [];
      })
      .catch((error) => {
        setIsLoading(false);
        logError(error);
      });
  }, [kudosAndCommentsEntityState.selectedComment]);

  useEffect(() => {
    if (kudosAndCommentsEntityState.isViewingCommentReactionsList) {
      fetchCommentReactionList();
      // Comment Reactions List replaces kudo as tab index 0
      setIndex(0);
      setIsViewingReactionList(true);
    } else {
      setIsViewingReactionList(false);
    }
  }, [
    kudosAndCommentsEntityState.isViewingCommentReactionsList,
    dispatch,
    fetchCommentReactionList
  ]);

  //  determine if the selected tab is 'other athlete' tab since
  // comments and achievements tabs are optional
  const isOtherAthletesTab = useCallback(() => {
    return (
      (commentsEnabled &&
        ((hasAchievements && index === 3) ||
          (!hasAchievements && index === 2))) ||
      (!commentsEnabled &&
        ((hasAchievements && index === 2) || (!hasAchievements && index === 1)))
    );
  }, [index, commentsEnabled, hasAchievements]);

  const isKudosTab = useCallback(() => {
    return index === 0;
  }, [index]);

  const fetchOtherAthletes = useCallback(() => {
    setIsLoading(true);
    createNetworkingClient()
      .get(`/feed/${entityEndpoint}/${entityId}/group_athletes`)
      .then(({ data }) => {
        setGroupedActivityData({ ...data, hasOtherAthletes: true });
        setIsLoading(false);
      });
  }, [entityEndpoint, entityId]);

  useEffect(() => {
    if (isKudosTab()) {
      fetchKudoList();
    }
    if (isOtherAthletesTab()) {
      fetchOtherAthletes();
    }
  }, [isKudosTab, fetchKudoList, fetchOtherAthletes, isOtherAthletesTab]);

  const toggleHover = () => {
    setHover((prev) => !prev);
  };

  const postKudo = () => {
    kudosAndCommentsEntityState.trackAnalytics({
      action: 'click',
      element: 'kudo_btn_on_modal'
    });
    const viewAthleteKudoData = {
      member_type: viewingAthlete.memberType,
      url: `/athletes/${viewingAthlete.id}`,
      name: viewingAthlete.name,
      avatar_url: viewingAthlete.avatarUrl,
      id: viewingAthlete.id,
      location: viewingAthlete.location,
      is_following: true // viewing athlete cannot follow themselves (this prevent the Follow button from showing)
    };
    createNetworkingClient()
      .post(`/feed/${entityEndpoint}/${entityId}/kudo`)
      .then(() =>
        setKudosData((prev) => ({
          ...prev,
          athletes: [viewAthleteKudoData].concat(prev.athletes),
          kudosable: false
        }))
      )
      .then(() => {
        dispatch({ type: ADD_KUDO });
      })
      .catch((error) => {
        logError(error);
      });
  };

  const isRecentlyFollowedAthlete = (kudoDataRow) =>
    followRequestApprovalStatusMap[kudoDataRow.id] &&
    followRequestApprovalStatusMap[kudoDataRow.id].requestApprovalStatus ===
      'approved';

  const isRecentlyRequestedAthlete = (kudoDataRow) =>
    followRequestApprovalStatusMap[kudoDataRow.id] &&
    followRequestApprovalStatusMap[kudoDataRow.id].requestApprovalStatus ===
      'pending';

  const onKudoDataRowButtonClick = (kudoDataRow) => {
    kudosAndCommentsEntityState.trackAnalytics({
      action: 'click',
      element: 'unfollow_btn_on_modal'
    });
    const kudoserId = kudoDataRow.id;
    const viewingAthleteId = viewingAthlete.id;
    if (
      isRecentlyRequestedAthlete(kudoDataRow) ||
      isRecentlyFollowedAthlete(kudoDataRow)
    ) {
      // Athlete reversing their follow add/request action
      createNetworkingClient()
        .delete(
          `/athletes/${viewingAthleteId}/follows/${followRequestApprovalStatusMap[kudoDataRow.id].followId}`
        )
        .then(() => {
          setAthleteFollowRequestMap((prevState) =>
            Object.assign(prevState, {
              [kudoserId]: { requestApprovalStatus: '' }
            })
          );
        })
        .then(toggleHover);
    } else {
      kudosAndCommentsEntityState.trackAnalytics({
        action: 'click',
        element: 'follow_btn_on_modal'
      });
      const payload = {
        follow: {
          following_id: kudoserId,
          follower_id: viewingAthleteId
        }
      };
      createNetworkingClient()
        .post(`/athletes/${viewingAthleteId}/follows`, payload)
        .then((response) => {
          const requestApprovalStatus = response.data.approved
            ? 'approved'
            : 'pending';
          setAthleteFollowRequestMap((prevState) =>
            Object.assign(prevState, {
              [kudoserId]: {
                requestApprovalStatus,
                followId: response.data.follow_id
              }
            })
          );
        })
        .then(toggleHover);
    }
  };

  const leaveAthleteGroup = () => {
    kudosAndCommentsEntityState.trackAnalytics({
      action: 'click',
      element: 'leave_athlete_group_btn_on_modal'
    });
    setIsLoading(true);
    createNetworkingClient()
      .delete(`/activities/${entityId}/grouping`)
      .then(() => {
        setGroupedActivityData({ athletes: [], hasOtherAthletes: false });
        setIsLoading(false);
      });
  };

  const processFollowButtonAttributes = (kudoDataRow) => {
    let label;
    let variant;
    if (isRecentlyFollowedAthlete(kudoDataRow)) {
      label = hover
        ? I18n.t(`${prefix}.unfollow`)
        : I18n.t(`${prefix}.following`);
      variant = 'default';
    } else if (isRecentlyRequestedAthlete(kudoDataRow)) {
      label = hover
        ? I18n.t(`${prefix}.follow_requested`)
        : I18n.t(`${prefix}.cancel_follow_request`);
      variant = 'default';
    } else {
      label = kudoDataRow.is_private
        ? I18n.t(`${prefix}.request_to_follow`)
        : I18n.t(`${prefix}.follow`);
      variant = 'primaryOutline';
    }
    return { label, variant };
  };

  const buildFollowButton = (kudoDataRow) => {
    const followButtonAttributes = processFollowButtonAttributes(kudoDataRow);
    return (
      <Button
        type="button"
        data-testid="follow-btn"
        variant={followButtonAttributes.variant}
        onClick={() => onKudoDataRowButtonClick(kudoDataRow)}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        className={styles.followBtn}
      >
        {followButtonAttributes.label}
      </Button>
    );
  };

  const spinner = (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  );

  const entriesFromFetchedData = (dataList) => {
    const components = [];
    dataList.athletes.forEach((kudoDataRow) => {
      components.push(
        <div className={styles.entry} key={kudoDataRow.id} data-testid="entry">
          <AvatarAndMeta
            athleteMetaData={kudoDataRow}
            showCommentReactions={false} // We're not rendering comments here.
            entityId={entityId}
            commentReactionAnalytics={commentReactionAnalytics}
          />
          {!kudoDataRow.is_following && buildFollowButton(kudoDataRow)}
        </div>
      );
    });
    return components;
  };

  const buildKudoList = () => {
    if (isLoading) {
      return spinner;
    }
    // activity owner is not allowed to kudo their own activity
    const kudoAllowed = !kudoData.isOwner && kudoData.kudosable;
    let kudoEntries = entriesFromFetchedData(kudoData);
    if (kudoEntries.length === 0) {
      kudoEntries = (
        <div className={styles.noResults}>
          {I18n.t(`${prefix}.no_kudos_yet`)}
        </div>
      );
    }
    return (
      <>
        <div className={styles.body}>{kudoEntries}</div>
        <div className={styles.footer}>
          <Button
            disabled={!kudoAllowed}
            type="button"
            onClick={postKudo}
            variant={kudoAllowed ? 'primary' : 'default'}
            className={styles.footerActionBtn}
          >
            {I18n.t(`${prefix}.give_kudos`)}
          </Button>
        </div>
      </>
    );
  };

  const buildCommentReactionList = () => {
    if (isLoading) {
      return spinner;
    }
    return <CommentReactionList reactionListData={commentReactionListData} />;
  };

  const buildOtherAthleteList = () => {
    if (isLoading) {
      return spinner;
    }
    const groupAthletesEntries = entriesFromFetchedData(groupedActivityData);
    return (
      <>
        <div className={styles.body}>{groupAthletesEntries}</div>
        {!isEmpty(groupedActivityData.athletes) &&
          viewingAthlete.id === entityOwner.id && (
            <div className={styles.footer}>
              <Button
                onClick={leaveAthleteGroup}
                className={styles.footerActionBtn}
                type="button"
              >
                {I18n.t(`${prefix}.leave_group`)}
              </Button>
            </div>
          )}
      </>
    );
  };

  const deleteComment = (comment) => {
    dispatch({
      type: DELETE_COMMENT_START,
      payload: { comment, entityId, element: 'modal' }
    });
  };

  const allowedCommentReactionEntities = [
    FEED_ENTRY_TYPES.activity,
    FEED_ENTRY_TYPES.groupActivity,
    FEED_ENTRY_TYPES.post
  ];

  const buildCommentList = () => {
    let thread = [];
    const showCommentReactions = allowedCommentReactionEntities.includes(
      entity
    );

    comments.forEach((entry) => {
      const commentReactionCount =
        entry.reactions_summary && entry.reactions_summary.reaction_count
          ? entry.reactions_summary.reaction_count
          : 0;
      const hasReacted =
        entry.reactions_summary && entry.reactions_summary.has_reacted
          ? entry.reactions_summary.has_reacted
          : false;
      const timeAgoTimestamp = buildTimeAgoTimestamp(entry.timestamp);

      thread.push(
        <div
          className={styles.entry}
          key={entry.comment_id}
          data-testid="entry"
        >
          <AvatarAndMeta
            athleteMetaData={entry.athlete}
            rowContent={entry.comment}
            showCommentReactions={showCommentReactions}
            commentReactionCount={commentReactionCount}
            entryId={entry.comment_id}
            hasReacted={hasReacted}
            entityId={entityId}
            commentReactionAnalytics={commentReactionAnalytics}
          />
          <div className={styles.timestampAndCommentActions}>
            <div className={styles.timestamp}>
              <Markup noWrap={true} content={timeAgoTimestamp} />
            </div>
            {(entry.deletable || entry.reportable || entry.quarantinable) && (
              <div className={styles.commentActions}>
                {entry.reportable && (
                  <LinkButton
                    variant="text"
                    data-testid="report-comment-btn"
                    className={styles.actionLinkButton}
                    href={`/comments/${entry.comment_id}/feedback`}
                  >
                    {I18n.t(`${prefix}.report`)}
                  </LinkButton>
                )}
                {entry.deletable && (
                  <Button
                    type="button"
                    className={styles.actionLinkButton}
                    variant="text"
                    data-testid="delete-comment-btn"
                    onClick={() => deleteComment(entry)}
                  >
                    {I18n.t(`${prefix}.delete`)}
                  </Button>
                )}
                {entry.quarantinable && (
                  <LinkButton
                    className={styles.actionLinkButton}
                    variant="text"
                    data-testid="quarantine-comment-btn"
                    href={`/admin/quarantine/comment_form?comment_id=${entry.comment_id}`}
                  >
                    {I18n.t(`${prefix}.soft_delete`)}
                  </LinkButton>
                )}
              </div>
            )}
          </div>
        </div>
      );
    });
    if (thread.length === 0) {
      thread = (
        <div className={styles.noResults}>
          {I18n.t(`${prefix}.no_comments_yet`)}
        </div>
      );
    }
    return (
      <>
        <div className={styles.body}>{thread}</div>
        <div className={`${styles.footer} ${styles.commentsForm}`}>
          <MentionableCommentForm
            viewingAthlete={viewingAthlete}
            autoFocus={true}
            entity={entity}
            clubMentionsEnabled={clubMentionsEnabled}
            entityId={entityId}
            parent="modal"
          />
        </div>
      </>
    );
  };

  const legacySpriteToStravaUiIcon = (sprite) => {
    const SILVER = '#999999';
    const GOLD = '#FEC835';
    const BRONZE = '#E5820A';
    switch (sprite) {
      case 'icon-at-kom-1':
        return <AchievementsKomHighlightedSmall fill={GOLD} />;
      case 'icon-at-kom-2':
        return <AchievementsTrophy02Small fill={GOLD} />;
      case 'icon-at-kom-3':
        return <AchievementsTrophy03Small fill={GOLD} />;
      case 'icon-at-kom-4':
        return <AchievementsTrophy04Small fill={GOLD} />;
      case 'icon-at-kom-5':
        return <AchievementsTrophy05Small fill={GOLD} />;
      case 'icon-at-kom-6':
        return <AchievementsTrophy06Small fill={GOLD} />;
      case 'icon-at-kom-7':
        return <AchievementsTrophy07Small fill={GOLD} />;
      case 'icon-at-kom-8':
        return <AchievementsTrophy08Small fill={GOLD} />;
      case 'icon-at-kom-9':
        return <AchievementsTrophy09Small fill={GOLD} />;
      case 'icon-at-kom-10':
        return <AchievementsTrophy10Small fill={GOLD} />;
      case 'icon-at-pr-1':
      case 'icon-yr-pr-1':
        return <AchievementsMedalPrSmall fill={GOLD} />;
      case 'icon-at-pr-2':
      case 'icon-yr-pr-2':
        return <AchievementsMedal02Small fill={SILVER} />;
      case 'icon-at-pr-3':
      case 'icon-yr-pr-3':
        return <AchievementsMedal03Small fill={BRONZE} />;
      default:
        return <AchievementsBadgeHighlightedSmall fill={SILVER} />;
    }
  };

  const buildAchievementList = () => {
    const achievements = segAndBestEffortAchievements
      .filter((achievement) => !achievement.hidden)
      .map((achievement) => {
        const achievementIcon = legacySpriteToStravaUiIcon(achievement.sprite);
        return (
          <div
            className={`${styles.entry} ${styles.achievementRow}`}
            key={`${achievement.id}-${achievement.name}`}
            data-testid="entry"
          >
            <div className={styles.achievementIcon}>{achievementIcon}</div>
            <a
              className={styles.segmentName}
              href={`/activities/${achievement.activity_id}/segments/${achievement.id_string}`}
            >
              {achievement.name}
            </a>
            <span className={styles.description}>
              {achievement.description}
            </span>
            <span className={styles.elapsedTime}>
              <Markup noWrap={true} content={achievement.elapsed_time} />
            </span>
          </div>
        );
      });
    return <div className={styles.body}>{achievements}</div>;
  };

  // tab order - kudos, comments (optional), achievements (optional), other athletes (optional)
  const onTabChange = (selectedTab) => {
    setIndex(selectedTab);
    if (isViewingCommentReactionList) {
      dispatch({
        type: CLOSE_COMMENT_REACTION_LIST,
        payload: { selectedEntityId: entityId }
      });
      // Go back to Comments tab
      setIndex(1);
    } else if (selectedTab === 0) {
      // kudos tab
      fetchKudoList();
    } else if (isOtherAthletesTab()) {
      // other athletes tab
      fetchOtherAthletes();
    }
  };

  const modalTitle = () => {
    return (
      <div className={styles.title}>
        <div className={styles.avatar}>
          <Avatar
            name={entityOwner.name}
            src={entityOwner.displayImage}
            type={entityOwner.type}
            badge={entityOwner.memberType}
            href={entityOwner.profileUrl}
            size="small"
            onClick={() => {
              kudosAndCommentsEntityState.trackAnalytics({
                action: 'click',
                element: 'profile_picture',
                properties: { element_source: 'kudos_and_comments_modal_title' }
              });
            }}
          />
        </div>
        <div className={styles.description} id="kudos-comments-modal-title">
          <Markup noWrap={true} content={title} />
        </div>
      </div>
    );
  };

  const kudosTabTitle = () => {
    return I18n.t(`${prefix}.kudos`).concat(
      ` (${Cldr.formatDecimal(kudosCount)})`
    );
  };

  const commentsTabTitle = () => {
    return I18n.t(`${prefix}.comments`).concat(
      ` (${Cldr.formatDecimal(comments.length)})`
    );
  };

  const acheivementsTabTitle = () => {
    return I18n.t(`${prefix}.achievements`).concat(
      ` (${Cldr.formatDecimal(
        segAndBestEffortAchievements.filter((a) => !a.hidden).length
      )})`
    );
  };

  const otherAthletesTabTitle = () => {
    return I18n.t(`${prefix}.other_athletes`);
  };

  const commentReactionListBackButton = () => {
    return (
      <div className={styles.commentReactionListBackButtonChildren}>
        <SvgActionsArrowLeftNormalSmall />
        <div data-testid="comment_reaction_back_btn">
          {I18n.t(`${prefix}.back`)}
        </div>
      </div>
    );
  };

  const dismissModal = () => {
    if (kudosAndCommentsEntityState.isViewingCommentReactionsList) {
      dispatch({
        type: CLOSE_COMMENT_REACTION_LIST,
        payload: { selectedEntityId: entityId }
      });
    }

    // if the modal was opened from a hash, remove the hash so that
    // adding it again will trigger the modal to open again (this only happens
    // in the super edge case of someone clicking a notification to view kudos
    // or comments on the current activity, dismissing the modal, and then clicking
    // the notification to view the kudos or comments on the current activity again)
    if (
      window.location.href.includes('#comments') ||
      window.location.href.includes('#kudos')
    ) {
      window.history.replaceState(null, null, ' ');
    }

    dispatch({ type: CLOSE_MODAL });
  };

  return (
    <ModalWithTabs
      title={modalTitle()}
      isOpen={true}
      onDismiss={() => dismissModal()}
      aria-labelledby="kudos-comments-modal-title"
    >
      <ModalTabs index={index} onChange={onTabChange} defaultIndex={0}>
        {!isViewingCommentReactionList ? (
          <ModalTabList>
            <ModalTab>{kudosTabTitle()}</ModalTab>
            {commentsEnabled && <ModalTab>{commentsTabTitle()}</ModalTab>}
            {hasAchievements && <ModalTab>{acheivementsTabTitle()}</ModalTab>}
            {groupedActivityData.hasOtherAthletes && (
              <ModalTab>{otherAthletesTabTitle()}</ModalTab>
            )}
          </ModalTabList>
        ) : (
          <ModalTabList>
            <ModalTab className={styles.commentReactionListBackButton}>
              {commentReactionListBackButton()}
            </ModalTab>
          </ModalTabList>
        )}
        {!isViewingCommentReactionList ? (
          <ModalTabPanels>
            <ModalTabPanel>
              <div className={styles.tabContent}>{buildKudoList()}</div>
            </ModalTabPanel>
            {commentsEnabled && (
              <ModalTabPanel>
                <div className={styles.tabContent}>{buildCommentList()}</div>
              </ModalTabPanel>
            )}
            {hasAchievements && (
              <ModalTabPanel>
                <div className={styles.tabContent}>
                  {buildAchievementList()}
                </div>
              </ModalTabPanel>
            )}
            {hasOtherAthletes && (
              <ModalTabPanel>
                <div className={styles.tabContent}>
                  {buildOtherAthleteList()}
                </div>
              </ModalTabPanel>
            )}
          </ModalTabPanels>
        ) : (
          <ModalTabPanels>
            <ModalTabPanel>
              <div>{buildCommentReactionList()}</div>
            </ModalTabPanel>
          </ModalTabPanels>
        )}
      </ModalTabs>
    </ModalWithTabs>
  );
};

KudosCommentsAndAchievementsModal.propTypes = {
  title: PropTypes.string.isRequired,
  segAndBestEffortAchievements: segAndBestEffortAchievementsProps,
  viewingAthlete: viewingAthleteProps.isRequired,
  entity: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  hasOtherAthletes: PropTypes.bool,
  entityOwner: entityOwnerProps.isRequired,
  clubMentionsEnabled: PropTypes.bool.isRequired,
  kudosCount: PropTypes.number.isRequired,
  commentsEnabled: PropTypes.bool, // this is currently only used by posts (AR work)
  commentReactionAnalytics: PropTypes.shape({
    category: PropTypes.string.isRequired,
    page: PropTypes.string.isRequired,
    buttonElement: PropTypes.string.isRequired,
    listElement: PropTypes.string.isRequired
  }).isRequired
};

KudosCommentsAndAchievementsModal.defaultProps = {
  segAndBestEffortAchievements: [],
  hasOtherAthletes: false,
  commentsEnabled: true
};
export default KudosCommentsAndAchievementsModal;
